import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./menu-bottom.sass";
import { ReactComponent as MenuIcon1 } from "../../assets/images/menu-icon-1.svg";
import { ReactComponent as MenuIcon2 } from "../../assets/images/menu-icon-2.svg";
import { ReactComponent as MenuIcon3 } from "../../assets/images/menu-icon-3.svg";
import { ReactComponent as MenuIcon4 } from "../../assets/images/menu-icon-4.svg";
import { StateContext } from "../../context/StateContext";

const MenuBottom = () => {
  const STATE = useContext(StateContext);

  if (!STATE.lastData || !STATE.subscribed || STATE.subscribed === null)
    return <React.Fragment />;

  return (
    <div className="main-area-field _fixed-b">
      <div className="main-area">
        <div className="menu-bottom">
          <Link
            to={`/device/${STATE.subscribed.id}`}
            className={`menu-bottom-item${
              STATE.tab === "main" ? " menu-bottom-item-active" : ""
            }`}
          >
            <MenuIcon1 className="item-icon" />
            <div className="item-text">пульт</div>
          </Link>
          <Link
            to={`/device/${STATE.subscribed.id}/timers`}
            className={`menu-bottom-item${
              STATE.tab === "timers" ? " menu-bottom-item-active" : ""
            }`}
          >
            <MenuIcon2 className="item-icon" />
            <div className="item-text">таймеры</div>
          </Link>
          <Link
            to={`/device/${STATE.subscribed.id}/chimes`}
            className={`menu-bottom-item${
              STATE.tab === "chimes" ? " menu-bottom-item-active" : ""
            }`}
          >
            <MenuIcon3 className="item-icon" />
            <div className="item-text">КУРАНТЫ</div>
          </Link>
          <Link
            to={`/device/${STATE.subscribed.id}/settings`}
            className={`menu-bottom-item${
              STATE.tab === "settings" ? " menu-bottom-item-active" : ""
            }`}
          >
            <MenuIcon4 className="item-icon" />
            <div className="item-text">НАСТРОЙКИ</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MenuBottom;
