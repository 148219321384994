export default [
  { id: 0, title: '00:00 - 00:59' },
  { id: 1, title: '01:00 - 01:59' },
  { id: 2, title: '02:00 - 02:59' },
  { id: 3, title: '03:00 - 03:59' },
  { id: 4, title: '04:00 - 04:59' },
  { id: 5, title: '05:00 - 05:59' },
  { id: 6, title: '06:00 - 06:59' },
  { id: 7, title: '07:00 - 07:59' },
  { id: 8, title: '08:00 - 08:59' },
  { id: 9, title: '09:00 - 09:59' },
  { id: 10, title: '10:00 - 10:59' },
  { id: 11, title: '11:00 - 11:59' },
  { id: 12, title: '12:00 - 12:59' },
  { id: 13, title: '13:00 - 13:59' },
  { id: 14, title: '14:00 - 14:59' },
  { id: 15, title: '15:00 - 15:59' },
  { id: 16, title: '16:00 - 16:59' },
  { id: 17, title: '17:00 - 17:59' },
  { id: 18, title: '18:00 - 18:59' },
  { id: 19, title: '19:00 - 19:59' },
  { id: 20, title: '20:00 - 20:59' },
  { id: 21, title: '21:00 - 21:59' },
  { id: 22, title: '22:00 - 22:59' },
  { id: 23, title: '23:00 - 23:59' },
]