import React, { useEffect, useState, useContext } from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import Loading from '../../../components/loading/loading'
import { StateContext } from '../../../context/StateContext'
import '../../../assets/styles/modal.sass'
import axios from 'axios'
import Modal from 'react-modal'
import ReactJson from 'react-json-view'
import { modes } from '../../../utils/helpers'

const Logs = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [logs, setLogs] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const STATE = useContext(StateContext)

    useEffect(() => {
        if(isOpen){
            fetchLogs()
        }else{
            setLogs([])
            setIsLoading(true)
        }
    }, [isOpen])

    const fetchLogs = async () => {
        const logs = await axios.get(`/logs/${STATE.subscribed.id}`)

        // console.log('logs', logs)
        setLogs(logs.data.logs)

        setIsLoading(false)
    }

    const logsList = logs.map(item => {

        const json = {
            data: JSON.parse(item.data),
            modeHex: JSON.parse(item.data).mode,
            mode: modes[JSON.parse(item.data).mode]
        }

        if (item.type === 'WEB') {
            json.user = JSON.parse(item.user)
        }

        return (
            <div key={item.id}>
                <ReactJson
                    src={json}
                    collapsed={true}
                    displayObjectSize={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayArrayKey={false}
                    quotesOnKeys={false}
                    theme={item.type === 'WEB' ? 'monokai' : 'rjv-default'}
                    name={moment(item.createdAt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')}
                />
            </div>
        )
    })

    if (STATE.user && STATE.user.role === 'ADMIN') {
        return (
            <div className="logs-container">
                <button className="btn btn-yellow-border" onClick={() => setIsOpen(true)}>История</button>

                <Modal
                    isOpen={isOpen}
                    ariaHideApp={false}
                    onRequestClose={() => setIsOpen(false)}
                    shouldCloseOnOverlayClick={true}
                    className="modal modal-ringing">
                    <div className="block ringing-area">
                        <div className="ringing-scroll">
                            {isLoading && <div style={{ height: '28px' }}><Loading /></div>}
                            {(!isLoading && logs.length === 0)
                                &&
                                <div style={{
                                    textAlign: 'center'
                                }}>
                                    История отсутствует
                                </div>
                            }
                            {(!isLoading && logs.length > 0)
                                && logsList}
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    return ''
}

export default Logs
