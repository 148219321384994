import React from 'react'
import { Link } from "react-router-dom"
import { ReactComponent as BackArrow } from "../../assets/images/back-arrow.svg"
import { ReactComponent as NotFoundIcon } from "../../assets/images/not-found.svg"

const NotFound = () => {
  return (
    <div className="main-area-field full-height">
      <div className="main-area full-height">
        <div className="page-area center-h">
          <div className="back-link-block">
            <Link to="/" className="back-link">
              <BackArrow className="back-icon" />
              <div className="back-text">На главную</div>
            </Link>
            <div className="not-found-text">
              Страница
              <br />не найдена
            </div>
            <NotFoundIcon className="not-found-icon" />
            <div className="not-found-info">
              Попробуйте перезагрузить
              страницу
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
