export default [
    { id: 0, title: 'ВЫБЕРИТЕ ЗВОН'},
    { id: 1, title: 'Будничный'},
    { id: 2, title: 'Праздничный'},
    { id: 3, title: 'Традиционный'},
    { id: 4, title: 'Литургический'},
    { id: 5, title: 'Встречный'},
    { id: 6, title: 'Пасхальный'},
    { id: 7, title: 'Всенощная'},
    { id: 8, title: 'Крестный ход'},
    { id: 9, title: 'Погребальный'},
    { id: 10, title: 'Благовест'},
    { id: 11, title: 'Куранты-1'},
    { id: 12, title: 'Куранты-2'},
    { id: 13, title: 'Куранты-3'},
    { id: 14, title: 'Куранты-4'},
    { id: 15, title: 'Куранты-5'},
]