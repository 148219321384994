import React from 'react'
import {ReactComponent as Y} from "../../../assets/images/landing/youtube.svg"
import {ReactComponent as V} from "../../../assets/images/landing/vk.svg"
import {ReactComponent as In} from "../../../assets/images/landing/instagram.svg"

const Footer = () => {
  return (
    <footer className="container-area-field footer">
      <div className="container-main-area">
        <div className="footer-area">
          <div className="footer-nav-area">
            <div className="footer-nav-block">
              <a href="/instruction.pdf" target="_blank" smooth="true">
                Инструкция
              </a>
            </div>
            {/*<div className="footer-nav-block">*/}
            {/*  <HashLink to="/#publications" smooth="true">Публикации</HashLink>*/}
            {/*</div>*/}
            <div className="footer-nav-block">
              <a href="/application.pdf">Приложение</a>
            </div>
            <div className="footer-nav-block footer-soc">
              <p>Мы в соц. сетях</p>
              <div className="soc-area">
                <a
                  href="https://vk.com/zolotoyzvon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <V />
                </a>
                <a
                  href="https://youtube.com/channel/UCpP_l0N4ytXd5bP5cJlDmHA"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Y />
                </a>
                {/* <a href="https://instagram.com/zolotoyzvon?utm_medium=copy_link" target="_blank" rel="noopener noreferrer"><In /></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
