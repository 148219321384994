import React from "react";
import Devices from "./Devices";
import Header from "../../components/header/header";

const Cabinet = () => {
    const previous = localStorage.getItem('previous');

    return (
      <div className="main-area-field full-height">
        <div className="main-area full-height">
          <div className="page-area color-white no-p">
            {!previous && <Header title={"Устройства"} />}
            {previous && (
              <Header
                title={"Устройства"}
                backTitle="Пульт"
                backUrl={`/device/${previous}`}
              />
            )}

            <div className="page-area center-h color-white">
              <div className="devices-content">
                <Devices />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Cabinet;
