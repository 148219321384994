export default {
  1: "1й",
  2: "2й",
  3: "3й",
  4: "4й",
  5: "5й",
  6: "6й",
  7: "7й",
  8: "8й",
  9: "9й",
  10: "10й",
  11: "11й",
  12: "12й",
  13: "13й",
  14: "14й",
};