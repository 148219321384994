import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../../../context/StateContext";
import Loading from "../../../components/loading/loading";
import Logs from "./Logs";
import Header from "../../../components/header/header";

const ImpactDuration = () => {
  const STATE = useContext(StateContext);
  const [bells, setBells] = useState([]);

  const changeTime = (index, move) => {
    let newBells = [...bells];
    const time = newBells[index].time;

    if (move === "dec") {
      if (time > 0) {
        newBells[index].time = newBells[index].time - 1;
      }
    } else {
      if (time < 255) {
        newBells[index].time = newBells[index].time + 1;
      }
    }

    setBells(newBells);
  };

  const bellsList = bells.map((bell, index) => {
    if (index < STATE.lastData.settings.count) {
      return (
        <div className="force-item" key={index}>
          <div className="force-num">№ {index + 1}</div>
          <div className="force-count-area">
            <div className="dec" onClick={() => changeTime(index, "dec")}>
              -
            </div>
            <div className="count">{bell.time}</div>
            <div className="inc" onClick={() => changeTime(index, "inc")}>
              +
            </div>
          </div>
        </div>
      );
    } else return "";
  });

  useEffect(() => {
    const newBells = [];

    STATE.lastData.bells.forEach((bell) => {
      newBells.push({ power: bell.power, time: bell.time });
    });
    setBells(newBells);
  }, []);

  const save = (e) => {
    STATE.sendData({ bells });
  };

  return (
    <div className="main-area-field full-height">
      <div className="main-area full-height">
        <div className="page-area no-p">
          <Header
            title={"Длительность удара"}
            backTitle="Настройки"
            backUrl={`/device/${STATE.subscribed.id}/settings`}
          />
          <Logs />

          <div className="page-area center-h">
            <div className="content">
              <div className="block force-block">
                <div className="force-list">{bellsList}</div>
              </div>
              <div className="button-area _center">
                <button
                  className="btn btn-yellow chimes-save"
                  onClick={(e) => save(e)}
                >
                  {!STATE.isSending && "СОХРАНИТЬ"}
                  {STATE.isSending && <Loading />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactDuration;
