import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../../../context/StateContext";
import Loading from "../../../components/loading/loading";
import Logs from "./Logs";
import moment from "moment";
import "moment/locale/ru";
import Header from "../../../components/header/header";
import TimeZoneSelector from "../../../components/modal/timezoneSelect";
import zones from "../../../helpers/timezones";
import { ReactComponent as SelectIcon } from "../../../assets/images/select-icon.svg";

const General = () => {
  const STATE = useContext(StateContext);
  const [count, setCount] = useState(0);
  const [tempo, setTempo] = useState(0);
  const [alarm, setAlarm] = useState(0);
  const [reload, setReload] = useState(0);
  const [timeSync, setTimeSync] = useState(false);
  const [timeZoneOpen, setTimeZoneOpen] = useState(false);
  const [deviceTime, setDeviceTime] = useState(null);
  const [serverTime, setServerTime] = useState(null);
  const [timeZone, setTimeZone] = useState(0);
  const [debug, setDebug] = useState("000000000000000000");

  useEffect(() => {
    setCount(STATE.lastData.settings.count);
    setTempo(STATE.lastData.settings.tempo);
    setAlarm(STATE.lastData.alarm);
    setDeviceTime(STATE.lastData.deviceTime);
    setServerTime(STATE.lastData.serverTime);
    if (STATE.lastData?.debug) setDebug(STATE.lastData.debug);

    if (STATE.lastData.reload) {
      setReload(STATE.lastData.reload);
    }
  }, []);

  useEffect(() => {
    if (!!STATE.lastData) {
      if (STATE.lastData.settings.version >= 3.6) {
        setTimeZone(+(STATE.lastData.timeSync.timeZone));
        setTimeSync(STATE.lastData.timeSync.isSync === 1);
      } else {
        setTimeSync(STATE.lastData.timeSync === 1);
      }

      if (STATE.lastData?.debug) setDebug(STATE.lastData.debug);

      setDeviceTime(STATE.lastData.deviceTime);
      setServerTime(STATE.lastData.serverTime);

      if (STATE.lastData.reload) {
        setReload(STATE.lastData.reload);
      }
    }
  }, [STATE.lastData]);

  const changeCount = (move) => {
    if (move === "dec") {
      if (count > 1) {
        setCount(count - 1);
      }
    } else {
      if (count < 14) {
        setCount(count + 1);
      }
    }
  };

  const changeTempo = (move) => {
    if (move === "dec") {
      if (tempo > 1) {
        setTempo(tempo - 1);
      }
    } else {
      if (tempo < 10) {
        setTempo(tempo + 1);
      }
    }
  };

  const save = () => {
    const data = {
      settings: { count, tempo: tempo === 10 ? 0 : tempo },
      alarm,
      timeSync,
    };

    if (!!STATE.lastData.files && STATE.lastData.files.length > 0) {
      data["reload"] = reload;
    }

    if (STATE.lastData.settings.version < 3.6) {
      if (
        (STATE.lastData.debug && debug.length === 18 || debug.length === 10) &&
        debug !== STATE.lastData.debug
      ) {
        data["debug"] = debug;
      }
    } else {
      if (
        STATE.lastData.debug && debug.length === 2 &&
        debug !== STATE.lastData.debug
      ) {
        data["debug"] = debug;
      }

      data.timeZone = timeZone;
    }

    // console.log(data);

    STATE.sendData(data);
  };

  const formatTemp = (temp) => temp >= 0 ? `+${temp}` : `-${temp}`;

  const formatVoltage = (voltage) => {
    const v1 = voltage.toString().slice(0, voltage.toString().length - 1);
    const v2 = voltage.toString().slice(-1);

    return `${v1}.${v2}`;
  }

  let Y,
    M,
    D,
    h,
    m,
    s = "";

  if (deviceTime) {
    Y = 2000 + deviceTime.year;
    M =
      deviceTime.month.toString().length === 2
        ? deviceTime.month
        : `0${deviceTime.month}`;
    D =
      deviceTime.day.toString().length === 2
        ? deviceTime.day
        : `0${deviceTime.day}`;

    h =
      deviceTime.hour.toString().length === 2
        ? deviceTime.hour
        : `0${deviceTime.hour}`;
    m =
      deviceTime.minute.toString().length === 2
        ? deviceTime.minute
        : `0${deviceTime.minute}`;
    s =
      deviceTime.second.toString().length === 2
        ? deviceTime.second
        : `0${deviceTime.second}`;
  }

  const deviceTimeString = `${D}.${M}.${Y} ${h}:${m}:${s}`;

  const formatVersion = () => {
    if (STATE.lastData.settings.version.toString().indexOf(".") < 0) {
      return STATE.lastData.settings.version.toString() + ".0";
    } else return STATE.lastData.settings.version.toString();
  }

  return (
    <div className="main-area-field full-height">
      <div className="main-area full-height">
        <div className="page-area no-p">
          <Header
            title={"Основные"}
            backTitle="Настройки"
            backUrl={`/device/${STATE.subscribed.id}/settings`}
          />

          <Logs />

          <div className="page-area center-h">
            <div className="content">
              <div className="block general-block">
                <div className="general-title">Количество колоколов</div>
                <div className="general-item">
                  <div className="force-count-area">
                    <div onClick={() => changeCount("dec")} className="dec">
                      -
                    </div>
                    <div className="count">{count}</div>
                    <div onClick={() => changeCount("inc")} className="inc">
                      +
                    </div>
                  </div>
                </div>
                <div className="general-title">Темп звона, %</div>
                <div className="general-item">
                  <div className="force-count-area">
                    <div onClick={() => changeTempo("dec")} className="dec">
                      -
                    </div>
                    <div className="count">{tempo * 10}</div>
                    <div onClick={() => changeTempo("inc")} className="inc">
                      +
                    </div>
                  </div>
                </div>

                {STATE.lastData.settings.version > 3.5 && (
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <div className="general-title _mr">Температура, °C</div>
                      <div className="input-container code-device">
                        <div className="input-area">
                          <input
                            type="text"
                            readOnly
                            defaultValue={formatTemp(STATE.lastData.temp)}
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ width: "50%" }}>
                      <div className="general-title _mr">Напряжение, В</div>
                      <div className="input-container code-device">
                        <div className="input-area">
                          <input
                            type="text"
                            readOnly
                            defaultValue={formatVoltage(STATE.lastData.voltage)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="general-title _mr">Версия ПО контроллера</div>
                <div className="input-container code-device">
                  <div className="input-area">
                    <input
                      type="text"
                      readOnly
                      defaultValue={formatVersion()}
                    />
                  </div>
                </div>

                <div className="settings-item">
                  <div
                    className="general-title"
                    onClick={() => setAlarm(alarm === 1 ? 0 : 1)}
                  >
                    Аварийный режим
                  </div>
                  <div className="switch-area">
                    <input
                      type="checkbox"
                      id="alarm"
                      checked={alarm === 1}
                      onChange={() => setAlarm(alarm === 1 ? 0 : 1)}
                    />
                    <label
                      role="button"
                      tabIndex="0"
                      className="radio"
                      htmlFor="alarm"
                    />
                  </div>
                </div>

                <div className="general-title _mr">Время на устройстве</div>
                <div className="input-container code-device">
                  <div className="input-area">
                    <input type="text" readOnly value={deviceTimeString} />
                  </div>
                </div>

                <div className="general-title _mr" style={{ marginTop: 10 }}>
                  Время на сервере
                </div>
                <div className="input-container code-device">
                  <div className="input-area">
                    <input
                      type="text"
                      readOnly
                      value={moment(serverTime, "HH:mm:ss DD-MM-YYYY").format(
                        "DD.MM.YYYY HH:mm:ss"
                      )}
                    />
                  </div>
                </div>

                {STATE.lastData.settings.version > 3.5 && (
                  <div className="settings-item" style={{ paddingBottom: 0 }}>
                    <div className="general-title">Часовой пояс</div>

                    <div className="switch-area" style={{ width: "50%" }}>
                      <div
                        className="input-link"
                        onClick={() => setTimeZoneOpen(true)}
                      >
                        <div className="input-container _no-mr">
                          <div className="input-area">
                            <input
                              type="text"
                              readOnly
                              value={zones[timeZone]}
                              style={{ textAlign: "center" }}
                            />
                          </div>
                          <SelectIcon className="select-icon _abs" />
                        </div>
                      </div>
                    </div>

                    <TimeZoneSelector
                      selected={timeZone}
                      isOpen={timeZoneOpen}
                      setZone={setTimeZone}
                      handleClose={() => setTimeZoneOpen(false)}
                    />
                  </div>
                )}

                <div className="settings-item">
                  <div
                    className="general-title"
                    onClick={() => setTimeSync(!timeSync)}
                  >
                    Синхр. время
                  </div>
                  <div className="switch-area">
                    <input
                      type="checkbox"
                      id="time"
                      checked={timeSync}
                      onChange={() => setTimeSync(!timeSync)}
                    />
                    <label
                      role="button"
                      tabIndex="0"
                      className="radio"
                      htmlFor="time"
                    />
                  </div>
                </div>

                {!!STATE.lastData.files && STATE.lastData.files.length > 0 && (
                  <div className="settings-item" style={{ marginTop: 0 }}>
                    <div
                      className="general-title"
                      onClick={() => setReload(reload === 1 ? 0 : 1)}
                    >
                      Перезагрузить устр-во
                    </div>
                    <div className="switch-area">
                      <input
                        type="checkbox"
                        id="reload"
                        checked={reload === 1}
                        onChange={() => setReload(reload === 1 ? 0 : 1)}
                      />
                      <label htmlFor="reload" />
                    </div>
                  </div>
                )}

                {STATE.user &&
                  STATE.user.role === "ADMIN" &&
                  STATE.lastData?.debug && (
                    <React.Fragment>
                      <div className="general-title _mr">DEBUG</div>
                      <div className="input-container code-device">
                        <div className="input-area">
                          <input
                            type="text"
                            value={debug}
                            onChange={(e) => setDebug(e.target.value)}
                            maxLength={
                              STATE.lastData.settings.version < 3.6 ? "18" : "2"
                            }
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
              </div>
              <div className="button-area _center">
                <button
                  className="btn btn-yellow chimes-save"
                  onClick={() => save()}
                >
                  {!STATE.isSending && "СОХРАНИТЬ"}
                  {STATE.isSending && <Loading />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
