import React, { useState, useEffect } from 'react';
import Pub1 from '../../../assets/images/landing/pub-1.png'
import Pub2 from '../../../assets/images/landing/pub-2.png'
import Pub3 from '../../../assets/images/landing/pub-3.png'
import Pub4 from '../../../assets/images/landing/pub-4.png'
import axios from 'axios'

const Publications = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [videos, setVideos] = useState([])

  useEffect(() => {
    axios.get('/videos').then(res => {
      setVideos(res.data.videos)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) return (
    <div className="container-area-field about-color" id="publications">
      <div className="container-main-area">

      </div>
    </div>
  )

  const videoList = videos.map(item => {
    return (
      <div className="publication-item-area" key={item.id}>
        <div className="publication-item">
          <div className="publication-item-img">
              <iframe
                style={{ borderRadius: "30px" }}
                width="282"
                height="196"
                src={item.href}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            
          </div>
          <div className="publication-item-title">
            {item.title}
          </div>
          <div className="publication-item-text">
            {item.text}
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className="container-area-field about-color" id="publications">
      <div className="container-main-area">
        <div className="publication-area">
          <div className="title-home title-block ">
            <h2 className="title">Публикации</h2>
          </div>
          <div className="title-home title-block">
            <div className="title-sub">
              Смотрите на нашем YouTube канале где и как работает система
              “Электронный звонарь”
            </div>
          </div>
          <div className="publication-list">
            {videoList}
            {/* 
            <div className="publication-item-area">
              <div className="publication-item">
                <div className="publication-item-img">
                  <img src={Pub2} alt=""/>
                </div>
                <div className="publication-item-title">
                  Система в Орле
                </div>
                <div className="publication-item-text">
                  «Электронный звонарь» в Орле
                </div>
              </div>
            </div>

            <div className="publication-item-area">
              <div className="publication-item">
                <div className="publication-item-img">
                  <img src={Pub3} alt=""/>
                </div>
                <div className="publication-item-title">
                  Строящийся храм в Ливнах
                </div>
                <div className="publication-item-text">
                  Насколько нужен «Электронный звонарь» в строящемся храме
                </div>
              </div>
            </div>

            <div className="publication-item-area">
              <div className="publication-item">
                <div className="publication-item-img">
                  <img src={Pub4} alt=""/>
                </div>
                <div className="publication-item-title">
                  Система “Золотой звон”
                  в Болхове
                </div>
                <div className="publication-item-text">
                  Отзывы о нашей системе в храме г.Болхов
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publications;
