import React from 'react'
import {Link} from "react-router-dom"
import {ReactComponent as CloseIcon} from "../../assets/images/close.svg"
import MetaTags from 'react-meta-tags'

const EnterRegistration = () => {
  return (
    <div className="main-area-field full-height">
      <MetaTags>
        <title>Золотой звон | Вход или регистрация</title>
        <meta name="description" content="Войдите в личный кабинет или пройдите регистрацию в системе «Золотой звон»" />
        <meta property="og:title" content="Золотой звон | Вход или регистрация" />
      </MetaTags>

      <div className="main-area full-height">
        <div className="page-area center-h center-v no-p-top">
          <div className="enter-window">
            <Link to="/" className="close">
              <CloseIcon/>
            </Link>
            <div className="button-area _wrap">
              <Link to='login' className="btn btn-yellow-gradient">
                Вход в личный кабинет
              </Link>
              <Link to='signup' className="btn btn-yellow-border">
                Регистрация
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnterRegistration
