import React, { useState, useContext } from 'react'
import api from './api'
import Loading from '../../components/loading/loading'
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg'
import { StateContext } from "../../context/StateContext"
import GenerateDevice from './GenerateDevice'

const NewDevice = () => {
  const STATE = useContext(StateContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [code, setCode] = useState('')
  const [user, setUser] = useState('')
  const [errors, setErrors] = useState({})

  const submit = async () => {
    setErrors({})
    setIsLoading(true)

    try {
      await api.postDevice(STATE.user.role === 'ADMIN' && user ? { code, user } : { code })
      const devices = await api.getDevices()

      const newDevices = []
      devices.data.devices.forEach(device => {
        const users = []
        if (devices.data.device_holders) devices.data.device_holders.forEach(dh => {
          if (dh.device_id === device.id) {
            users.push(dh)
          }
        })

        newDevices.push({ ...device, users })
      })

      STATE.setDevices(newDevices)
      setCode('')
      setIsLoading(false)
      setIsOpen(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code) setErrors({ code: error.response.data.error.code })
        if (error.response.data.error.user) setErrors({ user: error.response.data.error.user })
        if (error.response.data.error.message) setErrors({ message: error.response.data.error.message })
      } else {
        setErrors({ message: 'Что-то пошло не так' })
      }
    }
  }

  return (
    <div className={`new-devices${isOpen ? ' _open' : ''}`}>
      <div className="new-devices-title" onClick={() => setIsOpen(!isOpen)} >
        <Arrow className={`arrow${isOpen ? ' _rotate' : ''}`} /> Добавление устройства
      </div>

      <div className="id-device">ID устройства "Электронный звонарь"</div>

      <div className="input-container code-device">
        <div className="input-area">
          <input
            name="code"
            type="text"
            placeholder=""
            maxLength="16"
            value={code}
            onChange={(e) => setCode(e.target.value.trim())}
          />
          {errors.code && <div className="error">{errors.code}</div>}
          {STATE.user.role === 'ADMIN'
            &&
            <input
              name="user"
              type="text"
              placeholder="ID пользователя (для себя оставить пустым)"
              maxLength="4"
              value={user}
              onChange={(e) => setUser(e.target.value.trim())}
              style={{ marginTop: '10px' }}
            />
          }
          {errors.user && <div className="error">{errors.user}</div>}
          {errors.message && <div className="error">{errors.message}</div>}
        </div>
      </div>

      {!isLoading &&
        <div className="button-area _between">
          <button className="btn btn-gray" onClick={() => { setCode(''); setErrors({}) }}>Отмена</button>
          <button className="btn btn-yellow-border" onClick={() => submit()}>Добавить</button>
        </div>
      }

      {STATE.user.role === 'ADMIN' && <GenerateDevice />}

      {isLoading && <Loading />}
    </div>
  )
}

export default NewDevice
