export default {
    7: '1 мин',
    8: '2 мин',
    9: '3 мин',
    10: '5 мин',
    11: '10 мин',
    12: '12 мин',
    13: '15 мин',
    14: '20 мин',
    15: '30 мин',
}