import React, { useState, useRef } from 'react'
import "../../assets/styles/modal.sass"
import Modal from 'react-modal'

import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { ReactComponent as Play } from '../../assets/images/landing/play.svg'
import { ReactComponent as Stop } from '../../assets/images/landing/stop.svg'

import { baseUrl } from '../../utils/helpers'

const BellSelector = ({ items, isOpen, closeModal, onSave, selected = 0 }) => {
  const [active, setActive] = useState(0)

  const [play, setPlay] = useState(false)
  // const [time, setTime] = useState(null)
  const player = useRef();

  const PlayF = () => {
    player.current.audio.current.play();
    setPlay(true)
    // setTime(player.current.audio.current.duration)
  }

  const StopF = () => {
    player.current.audio.current.pause()
    setPlay(false)
    // setTime(player.current.audio.current.duration)
  }

  const itemList = Object.keys(items).map(key => {
    const bellDescription = <div className="bell-block">
      {!items[key].error &&
        <div>
          Используется колоколов: {items[key].channel}
        </div>
      }

      {(key == active && !items[key].error) &&
        <div className="system-player bells-player">
          {play
            ?
            <div className="player-play" onClick={() => StopF()}>
              <Stop />
            </div>
            :
            <div className="player-play" onClick={() => PlayF()}>
              <Play />
            </div>
          }

          <AudioPlayer
            className="system-player-none"
            src={`${baseUrl}${items[key].id}.mp3`}
            ref={player}
          />
        </div>
      }

      {!items[key].error &&
        <div>
          {items[key].description}
        </div>
      }

      {parseInt(selected) !== parseInt(key) &&
        <div className='bell-controls' style={{ marginTop: '15px' }}>
          <button
            className="btn btn-yellow chimes-save"
            style={{ fontSize: '15px' }}
            onClick={() => select(key)}
          >
            Применить
          </button>
        </div>
      }

      {parseInt(selected) === parseInt(key) &&
        <div className='bell-controls' style={{ marginTop: '15px' }}>
          <div style={{ textTransform: 'uppercase' }}>Установленный звон</div>
        </div>
      }
    </div>

    return (
      <div className={"bell-item bell-item-thin" + (key == active ? " bell-item-active" : '')} key={key}>
        <div
          className='bell-name'
          onClick={() => {
            if (play) StopF()

            if (key == active) {
              setActive(0)
            } else {
              setActive(key)
            }
          }}
        >
          {items[key].name}
        </div>

        {bellDescription}
      </div>
    )
  })

  const select = (id) => {
    setActive(0)
    closeModal()
    onSave(id)
  }

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={(e) => { e.preventDefault(); e.stopPropagation(); setActive(0); closeModal() }}
      shouldCloseOnOverlayClick={true}
      className="modal modal-ringing">

      <div className="block ringing-area">
        <div className="ringing-title">
          <div className="ringing-name">
            Выберите звон
          </div>
        </div>

        <div className="ringing-scroll" style={{ paddingTop: '8px' }}>
          {itemList}
        </div>
      </div>
    </Modal>
  )
}

export default BellSelector
