import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../../../assets/styles/home.sass";
import Logo from "../../../assets/images/landing/logo.svg";
import LogoMobile from "../../../assets/images/landing/logo.svg";
import { ReactComponent as Pin } from "../../../assets/images/landing/pin.svg";
import { ReactComponent as Phone } from "../../../assets/images/landing/phone.svg";
import { ReactComponent as Mail } from "../../../assets/images/landing/mail.svg";

const Header = () => {
  const [menu, setMenu] = useState(false);

  return (
    <header className="container-area-field header-color" id="main">
      <div className="container-main-area header-color header-container">
        <div className="home-header">
          <div className="home-header-info">
            <Link to="/" className="header-info-area">
              <img src={Logo} alt="Золотой звон" />
            </Link>
            <div className="header-info-area">
              <div className="header-icon">
                <Pin style={{ width: "15px", height: "21px" }} />
              </div>

              <div className="header-info">
                <span>Орёл </span>, ул.Михалицына д.10
              </div>
            </div>
            <div className="header-info-area">
              <div className="header-icon">
                <Phone />
              </div>

              <div className="header-info">
                <a href="tel:+79192609003">
                  8 (919) <span>260-90-03</span>
                </a>
                <p className="work-time">пн-пт с 9.00 до 18.00</p>
              </div>
            </div>
            <div className="header-info-area">
              <div className="header-icon">
                <Mail />
              </div>

              <div className="header-info">
                <a href="mailto:maxim@zolotoyzvon.ru">
                  <span>maxim</span>@zolotoyzvon.ru
                </a>
              </div>
            </div>
          </div>
          <div className="home-header-nav">
            <div className="home-navigation">
              <HashLink
                to="/#advantages"
                className="navigation-link"
                smooth="true"
              >
                О нас
              </HashLink>
              <HashLink to="/#prices" className="navigation-link" smooth="true">
                Цены
              </HashLink>
              <HashLink to="/#system" className="navigation-link" smooth="true">
                о системе
              </HashLink>
              <HashLink
                to="/#publications"
                className="navigation-link"
                smooth="true"
              >
                публикации
              </HashLink>
              <a
                href="/application.pdf"
                className="navigation-link"
                smooth="true"
              >
                приложение
              </a>
              <HashLink
                to="/#contact"
                className="navigation-link"
                smooth="true"
              >
                контакты
              </HashLink>
            </div>
            <div className="button-area">
              <Link to="/login-or-signup" className="btn btn-yellow">
                Вход
              </Link>
            </div>
          </div>
        </div>
        <div className="home-header-mobile header-color">
          <div className="mobile-menu-area" onClick={() => setMenu(!menu)}>
            <button
              type="button"
              className={"burger" + (menu ? " burger--active" : "")}
              id="burger"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <Link to="/" className="header-logo-area_m">
            <img src={LogoMobile} alt="Золотой звон" />
          </Link>

          <Link to="/login-or-signup" className="btn btn-yellow btn--header">
            Войти
          </Link>
        </div>
      </div>
      <div className={"header-drop-menu-area_m" + (menu ? " _open" : "")}>
        {/*<HashLink*/}
        {/*  to="/#main"*/}
        {/*  className="drop-menu-link"*/}
        {/*  onClick={e => setMenu(false)}*/}
        {/*  scroll={(el) => el.scrollIntoView({behavior: 'instant', block: 'end'})}*/}
        {/*>*/}
        {/*  Главная*/}
        {/*</HashLink>*/}
        <HashLink
          to="/#advantages"
          className="drop-menu-link"
          onClick={(e) => setMenu(false)}
          smooth="true"
        >
          о нас
        </HashLink>
        <HashLink
          to="/#prices"
          className="drop-menu-link"
          onClick={(e) => setMenu(false)}
          smooth="true"
        >
          цены
        </HashLink>
        <HashLink
          to="/#system"
          className="drop-menu-link"
          onClick={(e) => setMenu(false)}
          smooth="true"
        >
          о системе
        </HashLink>
        <HashLink
          to="/#publications"
          className="drop-menu-link"
          onClick={(e) => setMenu(false)}
          smooth="true"
        >
          публикации
        </HashLink>
        <Link
          to="/login-or-signup"
          className="drop-menu-link"
          onClick={(e) => setMenu(false)}
        >
          ЛИЧНЫЙ КАБИНЕТ
        </Link>
        <a
          href="/application.pdf"
          className="drop-menu-link"
          onClick={() => setMenu(false)}
        >
          приложение
        </a>
        <HashLink
          to="/#contact"
          className="drop-menu-link"
          onClick={(e) => setMenu(false)}
          smooth="true"
        >
          контакты
        </HashLink>
      </div>
    </header>
  );
};

export default Header;
