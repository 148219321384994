import React, { useContext, useState } from 'react'
import { ReactComponent as Phone } from '../../../assets/images/landing/way-phone.svg'
import { ReactComponent as Email } from '../../../assets/images/landing/way-mail.svg'
import { MainContext } from '../../../context/MainContext'
import { ReactComponent as Close } from '../../../assets/images/close.svg'
import Loading from '../../../components/loading/loading'
import InputMask from 'react-input-mask'
import Modal from 'react-modal'
import api from './api'

const Contact = () => {
  const [, mainDispatch] = useContext(MainContext)

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [comment, setComment] = useState('')
  const [check, setCheck] = useState(false)
  const [errors, setErrors] = useState({})
  const [isSended, setIsSended] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const setIsOpen = (e) => {
    e.preventDefault()
    e.stopPropagation()
    mainDispatch({ type: 'OPEN_MODAL_AGREEMENT', payload: true })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (!check) {
      setErrors({ check: 'Необходимо согласиться с условиями' })
      return
    }

    setErrors({})

    try {
      setIsFetching(true)
      await api.feedback({ name, phone: phone.replace(/[^0-9.]/g, ''), comment })

      setComment('')
      setPhone('')
      setName('')
      setCheck(false)
      setIsSended(true)
      setIsFetching(false)
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error) setErrors(error.response.data.error)
        if (error.response.data.message) setErrors({ message: error.response.data.message })
      } else {
        setErrors({ message: 'Что-то пошло не так' })
      }
      setIsFetching(false)
    }
  }

  return (
    <div className="container-area-field" id="contact">
      <div className="container-main-area">
        <div className="contact-area">
          <div className="contact-info">
            <div className="title-home title-block top-line">
              <h2 className="title">Связь с нами</h2>
            </div>
            <div className="contact-info-text">
              У вас остались вопросы? Напишите нам - <br />
              мы ответим в ближайшее время!
            </div>
            <div className="contact-ways">
              <div className="contact-way way-phone">
                <Phone />
                <a href="tel:+79192609003">
                  8 (919) <span>260-90-03</span>
                </a>
              </div>
              <div className="contact-way way-email">
                <Email />
                <a href="mailto:maxim@zolotoyzvon.ru">
                  <span>maxim</span>@zolotoyzvon.ru
                </a>
              </div>
            </div>
          </div>
          <div className="contact-form-area">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-flex">
                <div className="input-container">
                  <div className="label">Имя</div>
                  <div className="input-area">
                    <input
                      type="text"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Имя"
                      value={name}
                    />
                  </div>
                  {errors.name && <div className="error">{errors.name}</div>}
                </div>
                <div className="input-container">
                  <div className="label">Телефон</div>
                  <div className="input-area">
                    <InputMask
                      mask="+7 (999) 999-99-99"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                    {/* <input type='text' name='phone'
                      maxLength={11}
                      onChange={e => setPhone(e.target.value)}
                      placeholder='Телефон' value={phone} /> */}
                  </div>
                  {errors.phone && <div className="error">{errors.phone}</div>}
                </div>
              </div>
              <div className="input-container">
                <div className="label">Сообщение</div>
                <div className="input-area">
                  <textarea
                    placeholder="Ваше сообщение"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
                {errors.comment && (
                  <div className="error">{errors.comment}</div>
                )}
              </div>

              <div className="checkbox-area">
                <label className="checkbox" onChange={() => setCheck(!check)}>
                  <input
                    type="checkbox"
                    className="normal-checkbox"
                    id="u-1"
                    name="request-access"
                    readOnly={true}
                    checked={check}
                  />
                  <div className="label" htmlFor="u-1"></div>
                  <span className="checkbox-text">
                    Я согласен с{" "}
                    <span onClick={(e) => setIsOpen(e)}>условиями</span>{" "}
                    передачи данных
                  </span>
                </label>
                {errors.check && <div className="error">{errors.check}</div>}
                {errors.message && (
                  <div className="error">{errors.message}</div>
                )}
              </div>
              <div className="button-area _left">
                <button type="submit" className="btn btn-yellow">
                  {isFetching ? <Loading /> : "Отправить сообщение"}
                </button>
              </div>
            </form>

            <Modal
              isOpen={isSended}
              ariaHideApp={false}
              className="modal modal-agreement"
            >
              <div className="modal-close" onClick={() => setIsSended(false)}>
                <Close />
              </div>
              <div className="modal-title-block">
                <div className="modal-title">Сообщение отправлено</div>
              </div>
              <div className="modal-text">
                С Вами свяжутся в ближайшее время
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
