import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/images/back-arrow.svg";
import { StateContext } from "../../../context/StateContext";
import BellSelect from "../../../components/modal/bellSelect";
import Logs from "./Logs";
import melodies15 from "../../../helpers/melodies15";
import axios from "axios";
import Header from "../../../components/header/header";

import { baseUrl } from "../../../utils/helpers";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ReactComponent as Play } from "../../../assets/images/landing/play.svg";
import { ReactComponent as Stop } from "../../../assets/images/landing/stop.svg";

import { ReactComponent as Refresh } from "../../../assets/images/refresh.svg";

const Bells = () => {
  const STATE = useContext(StateContext);
  const [deviceFiles, setDeviceFiles] = useState({});
  const [serverFiles, setServerFiles] = useState({});
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [play, setPlay] = useState(false);
  const [selected, setSelected] = useState(0);
  const [position, setPosition] = useState(0);

  const player = useRef();

  const PlayF = () => {
    player.current.audio.current.play();
    setPlay(true);
  };

  const StopF = () => {
    player.current.audio.current.pause();
    setPlay(false);
  };

  const init = async () => {
    const serverFiles = await axios.get("/bells");

    let newServerFiles = {};

    if (serverFiles?.data?.bells.length > 0)
      serverFiles.data.bells.forEach((file, index) => {
        newServerFiles[parseInt(file.id)] = file;
      });

    let newDeviceFiles = {};

    if (!!STATE.lastData.files)
      STATE.lastData.files.forEach((file, index) => {
        newDeviceFiles[index + 1] = file;
      });

    setServerFiles(newServerFiles);
    setDeviceFiles(newDeviceFiles);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!!STATE.lastData) {
      init();
    }
  }, [STATE.lastData]);

  const onSave = (key) => {
    setIsLoading(true);

    STATE.sendData({
      file: {
        position: parseInt(position),
        file: parseInt(key),
      },
    });

    setPosition(0);
    setSelected(0);
    setActive(0);
  };

  const closeModal = () => {
    setPosition(0);
    setSelected(0);
    setModalOpen(false);
  };

  const bellsList = melodies15.map((bell, index) => {
    if (bell.id > 0) {
      const deviceFile = index in deviceFiles ? deviceFiles[index].file : 0;

      let severFile = {
        id: 0,
        name: "Мелодия не найдена",
      };

      if (
        deviceFile > 0 &&
        deviceFile in serverFiles &&
        !serverFiles[deviceFile].error
      ) {
        severFile = serverFiles[deviceFile];
      }

      const bellDescription = (
        <div className="bell-block">
          <div className="bell-song">
            <strong>{severFile.name}</strong>
          </div>

          {severFile.id > 0 && (
            <div>Используется колоколов: {severFile.channel}</div>
          )}

          {bell.id == active && severFile.id > 0 && (
            <div className="system-player bells-player">
              {play ? (
                <div className="player-play" onClick={() => StopF()}>
                  <Stop />
                </div>
              ) : (
                <div className="player-play" onClick={() => PlayF()}>
                  <Play />
                </div>
              )}

              <AudioPlayer
                className="system-player-none"
                src={`${baseUrl}${severFile.id}.mp3`}
                ref={player}
              />
            </div>
          )}

          {severFile.id > 0 && <div>{severFile.description}</div>}

          <div className="bell-controls">
            <div
              className="bell-btn"
              onClick={() => {
                setPosition(index);
                setSelected(deviceFile);
                setModalOpen(true);
              }}
            >
              <Refresh /> <span>Заменить</span>
            </div>
          </div>
        </div>
      );

      return (
        <div
          className={
            "bell-item" + (bell.id == active ? " bell-item-active" : "")
          }
          key={index}
        >
          <div
            className="bell-name"
            onClick={() => {
              if (play) StopF();

              if (bell.id == active) {
                setActive(0);
              } else {
                setActive(bell.id);
              }
            }}
          >
            {bell.title}
          </div>

          {bellDescription}
        </div>
      );
    }

    return null;
  });

  return (
    <div className="main-area-field full-height">
      <div className="main-area full-height">
        <div className="page-area no-p">
          <Header />
          <Logs />

          <Link
            to={`/device/${STATE.subscribed.id}/settings`}
            className="title-block under-title"
          >
            <BackIcon className="icon" />
            <div className="title">Звоны на устройстве</div>
          </Link>

          <div className="page-area center-h">
            <div className="content">
              <div
                className={
                  "block general-block" + (isLoading ? " block-loading" : "")
                }
                style={{ paddingBottom: 0 }}
              >
                {bellsList}
              </div>

              <BellSelect
                selected={selected}
                items={serverFiles}
                isOpen={modalOpen}
                closeModal={closeModal}
                onSave={onSave}
                style={{ zIndex: 99 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bells;
