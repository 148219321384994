import React, { useContext, useEffect } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import axios from 'axios'
import Loading from './components/loading/loading'

import Home from './pages/home/Home'
import ModalChoiseRinging from './components/modal/modalChoiseRinging'

import MenuBottom from './components/menu-bottom/menu-bottom'
import EnterRegistration from './pages/enter-registration/Enter-Registration'
import NotFound from './pages/not-found/Not-Found'
import Enter from './pages/enter/Enter'
import Reset from './pages/reset/Reset'
import Registration from './pages/registration/Registration'
import Signal from './pages/signal/Signal'
import Cabinet from './pages/cabinet/Cabinet'

import { StateContext } from './context/StateContext'

const AppRouter = () => {
  const STATE = useContext(StateContext)

  useEffect(() => {
    const init = async () => {
      try {
        const token = localStorage.getItem('token')

        if (!!token) {
          const user = await axios.get('/users/me', {
            headers: { Authorization: `Bearer ${token}` }
          })

          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          STATE.setUser({ ...user.data.user, token })

          setTimeout(STATE.setIsGlobalLoading, 0, false)
        } else {
          localStorage.removeItem('token')
          STATE.setIsGlobalLoading(false)
        }
      } catch (error) {
        console.log('init error', error)
        STATE.setIsGlobalLoading(false)
      }
    }

    init()
  }, [])

  if (STATE.isGlobalLoading) {
    return <React.Fragment><Loading /></React.Fragment>
  }

  if (!STATE.user) {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/login-or-signup' component={EnterRegistration} />
          <Route exact path='/login' component={Enter} />
          <Route exact path='/reset-password' component={Reset} />
          <Route exact path='/signup' component={Registration} />

          <Route exact path='/device/:id' component={Signal} ><Redirect to="/" /></Route>
          <Route exact path='/device/:id/:tab' component={Signal} ><Redirect to="/" /></Route>
          <Route exact path='/device/:id/:tab/:settings' component={Signal} ><Redirect to="/" /></Route >

          <Route path='*' component={NotFound} />
        </Switch>
        <ModalChoiseRinging />
      </Router>
    )
  } else {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={Cabinet} />
          <Route exact path='/device/:id' component={Signal} />
          <Route exact path='/device/:id/:tab' component={Signal} />
          <Route exact path='/device/:id/:tab/:settings' component={Signal} />

          <Route exact path='/login'><Redirect to="/" /></Route>
          <Route exact path='/login-or-signup'><Redirect to="/" /></Route>
          <Route exact path='/reset-password'><Redirect to="/" /></Route>
          <Route exact path='/signup'><Redirect to="/" /></Route>

          <Route path='*' component={NotFound} />
        </Switch>
        <MenuBottom />
        <ModalChoiseRinging />
      </Router>
    )
  }
}

export default AppRouter
