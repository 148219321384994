export const hours = () => {
  return ['00'].concat(Array.from({ length: 23 }, (_, i) => `${i >= 9 ? i + 1 : `0${i + 1}`}`))
}

export const minute = () => {
  return ['00'].concat(Array.from({ length: 59 }, (_, i) => `${i >= 9 ? i + 1 : `0${i + 1}`}`))
}

export const modes = {
  0: 'Неизвестно',
  1: 'Радиопульт',
  2: 'Панель приборов',
  3: 'BT',
  4: 'ДТМФ',
  5: 'Облако',
  6: 'Таймер',
  7: 'Куранты',
}

export const baseUrl = 'https://vm-66693fff.na4u.ru/';
