import "../../assets/styles/modal.sass";
import Modal from "react-modal";
import React, { useState } from "react";
import Loading from "../loading/loading";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

const MelodyRename = ({
  deviceId,
  isOpen,
  handleClose,
  handleUpdate,
  title,
  melodyId,
}) => {
  const [newTitle, setNewTitle] = useState(title);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { addToast } = useToasts();

  const submit = (e) => {
    e.preventDefault();

    setError('');
    setLoading(true);

    const data = {
      melody_id: melodyId,
      title: newTitle.trim()
    };

    axios
      // .post("/melodies", data)
      .post(`/device_melodies/${deviceId}`, data)
      .then(() => {
        handleUpdate();
        handleClose();
      })
      .catch((error) => {
        if (error?.response?.data?.error?.title) {
          setError(error.response.data.error.title);
        } else {
          addToast(error.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }

        setLoading(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleClose();
      }}
      shouldCloseOnOverlayClick={true}
      className="modal modal-ringing"
    >
      <div className="block ringing-area">
        <form onSubmit={submit}>
          <div>
            <input
              className="input-title"
              type="text"
              value={newTitle}
              maxLength="64"
              onChange={(e) => setNewTitle(e.target.value)}
            />
            {error && <div className="error">{error}</div>}
          </div>

          <div style={{ marginTop: "20px" }}>
            {!loading && (
              <div className="title-form" style={{ height: "24px" }}>
                <button
                  type="button"
                  className="btn btn-save-title btn-save-title--reset"
                  onClick={() => handleClose()}
                >
                  Отмена
                </button>

                <button type="submit" className="btn btn-save-title">
                  Сохранить
                </button>
              </div>
            )}

            {loading && (
              <div className="title-form" style={{ height: "24px" }}>
                <Loading />
              </div>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MelodyRename;
