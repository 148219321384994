import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BellIcon } from "../../../assets/images/bell.svg";
import { ReactComponent as TimeIcon } from "../../../assets/images/time.svg";
import { ReactComponent as MainIcon } from "../../../assets/images/main.svg";
import { ReactComponent as MusicIcon } from "../../../assets/images/music.svg";
import { StateContext } from "../../../context/StateContext";
import Logs from "./Logs";

import ImpactForce from "./ImpactForce";
import ImpactDuration from "./ImpactDuration";
import General from "./General";
import Bells from "./Bells";
import Header from "../../../components/header/header";
import Constructor from "./Constructor";
import Melodies from "./Melodies";

const Settings = ({ tab }) => {
  const STATE = useContext(StateContext);
  const { version } = STATE.lastData.settings;

  if (typeof tab === "undefined")
    return (
      <div className="main-area-field full-height">
        <div className="main-area full-height">
          <div className="page-area no-p">
            <Header
              title={"Настройки"}
              backTitle="Пульт"
              backUrl={`/device/${STATE.subscribed.id}`}
            />
            <Logs />

            <div className="content">
              <div className="block setting-block">
                <Link
                  to={`/device/${STATE.subscribed.id}/settings/force`}
                  className="setting-link"
                >
                  <div className="icon-area">
                    <BellIcon className="setting-icon" />
                  </div>

                  <div className="setting-text">Сила удара</div>
                </Link>
                <Link
                  to={`/device/${STATE.subscribed.id}/settings/duration`}
                  className="setting-link"
                >
                  <div className="icon-area">
                    <TimeIcon className="setting-icon icon-time" />
                  </div>
                  <div className="setting-text">Длительность удара</div>
                </Link>

                {version >= 3.6 && version !== 5 && (
                  <Link
                    to={`/device/${STATE.subscribed.id}/settings/constructor`}
                    className="setting-link"
                  >
                    <div
                      className="icon-area"
                      style={{ marginLeft: "-3px", marginRight: "13px" }}
                    >
                      <MusicIcon className="setting-icon" />
                    </div>
                    <div className="setting-text">Конструктор звонов</div>
                  </Link>
                )}

                {/* {version == 5 && ( */}
                <Link
                  to={`/device/${STATE.subscribed.id}/settings/melodies`}
                  className="setting-link"
                >
                  <div
                    className="icon-area"
                    style={{ marginLeft: "-3px", marginRight: "13px" }}
                  >
                    <MusicIcon className="setting-icon" />
                  </div>
                  <div className="setting-text">Названия звонов</div>
                </Link>
                {/* )} */}

                <Link
                  to={`/device/${STATE.subscribed.id}/settings/general`}
                  className="setting-link"
                >
                  <div className="icon-area">
                    <MainIcon className="setting-icon" />
                  </div>
                  <div className="setting-text">Основные</div>
                </Link>

                {!!STATE.lastData.files && STATE.lastData.files.length > 0 && (
                  <Link
                    to={`/device/${STATE.subscribed.id}/settings/bells`}
                    className="setting-link"
                  >
                    <div className="icon-area">
                      <BellIcon className="setting-icon" />
                    </div>
                    <div className="setting-text">Звоны на устройстве</div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  if (tab === "force") return <ImpactForce />;
  if (tab === "duration") return <ImpactDuration />;
  if (tab === "general") return <General />;
  if (tab === "bells") return <Bells />;
  if (tab === "constructor") return <Constructor />;
  if (tab === "melodies") return <Melodies />;
};

export default Settings;
