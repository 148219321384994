import React, { useContext, useState, useEffect } from "react";
import { ReactComponent as SelectIcon } from "../../../assets/images/select-icon.svg";
import { StateContext } from "../../../context/StateContext";
import melodies15 from "../../../helpers/melodies15";
import melodies255 from "../../../helpers/melodies255";
import MelodySelect from "../../../components/modal/melodySelect";
import Loading from "../../../components/loading/loading";
import Logs from "./Logs";
import Header from "../../../components/header/header";
import { fetchMelodiesByDevice } from "../../../helpers/fetchMelodies";

const Main = () => {
  const STATE = useContext(StateContext);
  const [currentMusicHex, setCurrentMusicHex] = useState("00");
  const [melody, setMelody] = useState("00");
  const [melodyState, setMelodyState] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [titles, setTitles] = useState({});

  let melodies = [...melodies15];

  const fetchingTitles = async () => {
    const titles = await fetchMelodiesByDevice(STATE.lastData.deviceId);

    setTitles(titles);
  };

  const save = () => {
    console.log("melody", melody);
    if (melody !== "00") {
      if (currentMusicHex === "00") {
        STATE.sendData({ settings: { currentMusicHex: melody } });
      } else {
        STATE.sendData({ settings: { currentMusicHex: "00" } });
      }
    }
  };

  useEffect(() => {
    if (!!STATE.lastData) {
      console.log("STATE.lastData", STATE.lastData);

      setMelodyState(STATE.lastData.settings.currentMusicHEX !== "00");
      setCurrentMusicHex(STATE.lastData.settings.currentMusicHEX);
      if (STATE.lastData.settings.currentMusicHEX !== "00") {
        setMelody(STATE.lastData.settings.currentMusicHEX);
      }

      fetchingTitles();
    }
  }, [STATE.lastData]);

  // if (STATE.lastData.settings.version === 5) {
    let melodiesWTitles = [];

    const srcMelodies =
      STATE.lastData.maxMelodies !== undefined ? melodies255 : melodies15;

    [...srcMelodies]
      .filter((item) => item.id <= (STATE.lastData.maxMelodies ?? 15))
      .forEach((item) => {
        if (item.id in titles) {
          melodiesWTitles.push({ ...item, title: titles[item.id] });
        } else {
          melodiesWTitles.push(item);
        }
      });


    melodies = melodiesWTitles; 
  //}
    // console.log(melodies);

  let melodyTitle = melodies[0].title;

  melodies.forEach((item) => {
    if (parseInt(item.id) === parseInt(melody, 16)) melodyTitle = item.title;
  });

  return (
    <div className="main-area-field full-height no-signal-area">
      <div className="main-area full-height">
        <div className="page-area center-h _signal no-p">
          <Header title={"Пульт"} backTitle={"Устройства"} backUrl={"/"} />
          <Logs />

          <div className="content options-bells">
            <div style={{ width: "100%" }}>
              <div className="input-link" onClick={() => setModalOpen(true)}>
                <div className="input-container _no-mr">
                  <div className="input-area">
                    <input type="text" readOnly value={melodyTitle} className="main-bell" />
                  </div>
                  <SelectIcon className="select-icon _abs" />
                </div>
              </div>

              <MelodySelect
                isOpen={modalOpen}
                selected={melody}
                items={melodies}
                setModal={setModalOpen}
                setMelody={setMelody}
              />

              <div className="button-area">
                <button className="btn btn-yellow" onClick={() => save()}>
                  {!STATE.isSending && !melodyState ? "Включить" : ""}
                  {!STATE.isSending && melodyState ? "Выключить" : ""}

                  {STATE.isSending && <Loading />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
