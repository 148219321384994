export default {
    0: 'Не выбран',
    1: '-12',
    2: '-11',
    3: '-10',
    4: '-9:30',
    5: '-9',
    6: '-8',
    7: '-7',
    8: '-6',
    9: '-5',
    10: '-4',
    11: '-3:30',
    12: '-3',
    13: '-2',
    14: '-1',
    15: '+0',
    16: '+1',
    17: '+2',
    18: '+3',
    19: '+3:30',
    20: '+4',
    21: '+4:30',
    22: '+5',
    23: '+5:30',
    24: '+5:45',
    25: '+6',
    26: '+6:30',
    27: '+7',
    28: '+8',
    29: '+8:45',
    30: '+9',
    31: '+9:30',
    32: '+10',
    33: '+10:30',
    34: '+11',
    35: '+12',
    36: '+12:45',
    37: '+13', 
    38: '+14',
}
