import React, { useState, useRef } from 'react'
import Slider from 'react-slick'
import { HashLink } from 'react-router-hash-link'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import Check from '../../../assets/images/landing/check.png'
import SystemImg from '../../../assets/images/landing/system.png'
import SystemDots from '../../../assets/images/landing/dots.png'
import SystemMini from '../../../assets/images/landing/system-mini.png'
import { ReactComponent as Play } from '../../../assets/images/landing/play.svg'
import { ReactComponent as Stop } from '../../../assets/images/landing/stop.svg'
import { ReactComponent as Next } from '../../../assets/images/landing/next.svg'
import { ReactComponent as Arrow } from '../../../assets/images/landing/arrow.svg'
import { ReactComponent as Phone } from '../../../assets/images/landing/phone-blue.svg'
import Sound1 from '../../../audio/1.mp3'
import Sound2 from '../../../audio/16.mp3'
import Sound3 from '../../../audio/18.mp3'

const System = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  }

  const soundsArr = [
    { id: 1, name: 'Праздничный', sound: Sound1 },
    { id: 2, name: 'Будничный', sound: Sound2 },
    { id: 3, name: 'Традиционный', sound: Sound3 },
  ]

  const [current, setCurrent] = useState(0)
  const [play, setPlay] = useState(false)
  // const [time, setTime] = useState(null)
  const player = useRef();

  const PlayF = () => {
    console.log(player.current.audio.current.currentTime)
    player.current.audio.current.play();
    setPlay(true)
    // setTime(player.current.audio.current.duration)
  }

  const StopF = () => {
    player.current.audio.current.pause()
    setPlay(false)
    // setTime(player.current.audio.current.duration)
  }

  const NextF = () => {
    // setTime(player.current.audio.current.duration)
    if (current + 1 === soundsArr.length) {
      setCurrent(0)
    } else {
      setCurrent(current + 1)
    }
    setPlay(true)
  }

  return (
    <div className="container-area-field" id="system">
      <div className="container-main-area">
        <div className="system-area">
          <div className="title-home title-block">
            <h2 className="title">О системе</h2>
          </div>
          <div className="title-home title-block">
            <div className="title-sub">
              Нами были выбраны самые доступные и проверенные механизмы
            </div>
          </div>
          <div className="system-info-area">
            <div className="system-list">
              <div className="system-item">
                <div className="check-block">
                  <img src={Check} alt="" />
                </div>
                <div className="system-title">
                  Привод на двигателях постоянного тока
                </div>
                <div className="system-info">
                  Прост в обслуживании, доступен, позволяет производить звон как
                  в автоматическом, так и в ручном режиме
                </div>
              </div>
              <div className="system-item">
                <div className="check-block">
                  <img src={Check} alt="" />
                </div>
                <div className="system-title">Шим-контроллер</div>
                <div className="system-info">
                  Позволяет контролировать силу и задержку ударов
                </div>
              </div>
              <div className="system-item">
                <div className="check-block">
                  <img src={Check} alt="" />
                </div>
                <div className="system-title">Блок управления</div>
                <div className="system-info">
                  Позволяет реализовывать автоматизированное управление системой
                  удаленно
                </div>
              </div>
              <div className="system-item">
                <div className="check-block">
                  <img src={Check} alt="" />
                </div>
                <div className="system-title">Щиток уличный IP65</div>
                <div className="system-info">
                  Защищает систему от осадков и постороннего вмешательства
                </div>
              </div>
              <div className="system-item">
                <div className="check-block">
                  <img src={Check} alt="" />
                </div>
                <div className="system-title">Аккумулятор</div>
                <div className="system-info">
                  Обеспечивает безотказность системы даже в условиях постоянных
                  перебоев в сети
                </div>
              </div>
            </div>
            <div className="system-img-mini-area">
              <img
                className="system-item-img"
                src={SystemMini}
                alt=""
                width="278"
                height="163"
              />
            </div>
            <div className="slider slider-advantages system-list-slider">
              <Slider {...settings}>
                <div className="system-item">
                  {/*<div className='check-block'>*/}
                  {/*  <img src={Check} alt=''/>*/}
                  {/*</div>*/}
                  <div className="system-title">
                    Привод на двигателях постоянного тока
                  </div>
                  <div className="system-info">
                    Прост в обслуживании, доступен, позволяет производить звон
                    как в автоматическом, так и в ручном режиме
                  </div>
                </div>
                <div className="system-item">
                  {/*<div className='check-block'>*/}
                  {/*  <img src={Check} alt=''/>*/}
                  {/*</div>*/}
                  <div className="system-title">Шим-контроллер</div>
                  <div className="system-info">
                    Позволяет контролировать силу и задержку ударов
                  </div>
                </div>
                <div className="system-item">
                  {/*<div className='check-block'>*/}
                  {/*  <img src={Check} alt=''/>*/}
                  {/*</div>*/}
                  <div className="system-title">Блок управления</div>
                  <div className="system-info">
                    Позволяет реализовывать автоматизированное управление
                    системой удаленно
                  </div>
                </div>
                <div className="system-item">
                  {/*<div className='check-block'>*/}
                  {/*  <img src={Check} alt=''/>*/}
                  {/*</div>*/}
                  <div className="system-title">Щиток уличный IP65</div>
                  <div className="system-info">
                    Защищает систему от осадков и постороннего вмешательства
                  </div>
                </div>
                <div className="system-item">
                  {/*<div className='check-block'>*/}
                  {/*  <img src={Check} alt=''/>*/}
                  {/*</div>*/}
                  <div className="system-title">Аккумулятор</div>
                  <div className="system-info">
                    Обеспечивает безотказность системы даже в условиях
                    постоянных перебоев в сети
                  </div>
                </div>
              </Slider>
            </div>
            <div className="system-img-area">
              <img src={SystemImg} className="system-img" alt="О системе" />
              <img
                src={SystemDots}
                className="system-img-dots"
                alt="О системе"
              />
            </div>
          </div>
          <div className="system-sound-area">
            <div className="system-sound-title">Прослушать звоны</div>

            <div className="system-player">
              {play ? (
                <div className="player-play" onClick={(e) => StopF()}>
                  <Stop />
                </div>
              ) : (
                <div className="player-play" onClick={(e) => PlayF()}>
                  <Play />
                </div>
              )}

              <div className="sound-name">{soundsArr[current].name}</div>
              <AudioPlayer
                className="system-player-none"
                src={soundsArr[current].sound}
                ref={player}
              />
              <div className="player-next" onClick={(e) => NextF()}>
                <Next />
              </div>
            </div>
          </div>
          <div className="order-system-area">
            <HashLink className="call-area" to="/#contact" smooth="true">
              <div className="call-filter" />
              <div className="call-block">
                <div className="call-img-area">
                  <Phone />
                </div>
                <div>
                  <div className="call-text">Остались вопросы?</div>
                  <div className="call-text-bold">Позвоните нам!</div>
                </div>
              </div>
            </HashLink>
            <HashLink to="/#contact" smooth="true" className="btn btn-yellow">
              Купить электронный звонарь
              <Arrow />
            </HashLink>
            {/* <div className='order-price'>
              От <span>160 000 </span> ₽
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default System
