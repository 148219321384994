import React, { useEffect, useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import api from "./api";
import Loading from "../../components/loading/loading";
import { ReactComponent as Dots } from "../../assets/images/dots.svg";
import { StateContext } from "../../context/StateContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import NewDevice from "./NewDevice";

const Devices = () => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const STATE = useContext(StateContext);
  const [menu, setMenu] = useState(null);
  const [name, setName] = useState(null);
  const [info, setInfo] = useState(null);
  const [errors, setErrors] = useState({});
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      STATE.setIsConnecting(true);
      STATE.setSubscribed(null);
      STATE.setLastData(null);
      setMenu(null);

      const devices = await api.getDevices();

      const newDevices = [];
      devices.data.devices.forEach((device) => {
        const users = [];
        if (devices.data.device_holders)
          devices.data.device_holders.forEach((dh) => {
            if (dh.device_id === device.id) {
              users.push(dh);
            }
          });

        newDevices.push({ ...device, users });
      });

      STATE.setDevices(newDevices);

      if (STATE.redirectToOnly && newDevices.length === 1) {
        setRedirect(true);
        STATE.setRedirectToOnly(false);
      }

      setTimeout(setIsLoading, 500, false);
    } catch (error) {
      STATE.setDevices([]);
      setTimeout(setIsLoading, 500, false);
    }
  };

  const changeMenu = (event, id) => {
    event.preventDefault();
    if (menu === id) {
      setMenu(null);
      setIsSaving(false);
      setName(null);
      setInfo(null);
    } else {
      setMenu(id);
      STATE.devices.map((item) => {
        if (item.id === id) {
          setName(item.title);
          setInfo(item.info);
        }
      });
    }
  };

  const saveDevice = async (id) => {
    try {
      setIsSaving(true);

      await api.saveDevice(id, { title: name, info });
      STATE.changeDevice(id, { title: name, info });

      setMenu(null);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);

      if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.title)
          setErrors({ title: error.response.data.error.title });
      } else {
        setErrors({ message: "Что-то пошло не так" });
      }
    }
  };

  const deleteDevice = async (id) => {
    try {
      const isOk = window.confirm("Вы уверены?");

      if (isOk) {
        await api.deleteDevice(id);

        fetch();
      }
    } catch (error) {
      console.log("error", error);
      setErrors({ message: "Что-то пошло не так" });
    }
  };

  const deleteDeviceFrom = async (id, user) => {
    try {
      const isOk = window.confirm("Вы уверены?");

      if (isOk) {
        await api.deleteDeviceFrom({
          device_id: id,
          user_id: user,
        });

        fetch();
      }
    } catch (error) {
      console.log("error", error);
      setErrors({ message: "Что-то пошло не так" });
    }
  };

  const devicesList = STATE.devices.map((item) => {
    return (
      <div className="device-wrapper" key={item.id}>
        <Link
          to={`/device/${item.id}`}
          className="device-area"
          style={menu === item.id ? { zIndex: 99 } : {}}
          onClick={(e) => (item.title.length > 0 ? {} : e.preventDefault())}
        >
          <div className="device-name">
            {STATE.user.role === "ADMIN" && <span>[{item.id}] </span>}
            {item.title.length > 0 ? item.title : "Пустое устройство"}
          </div>
          
          {STATE.user.role === "ADMIN" && (
            <div
              className="devices-dots"
              onClick={(event) => changeMenu(event, item.id)}
            >
              <Dots />
            </div>
          )}
        </Link>

        {menu === item.id && (
          <div className="dots-menu-area" onClick={(e) => e.stopPropagation()}>
            <div className="input-container">
              <div className="input-area">
                <input
                  type="text"
                  placeholder="НАЗВАНИЕ УСТРОЙСТВА"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {errors.title && <div className="error">{errors.title}</div>}
              </div>
            </div>
            <div className="input-container">
              <div className="input-area">
                <textarea
                  placeholder="КОММЕНТАРИЙ"
                  value={info}
                  onChange={(e) => setInfo(e.target.value)}
                ></textarea>
                {errors.message && (
                  <div className="error">{errors.message}</div>
                )}
              </div>
            </div>
            <div className="button-area _between">
              <button
                className="btn btn-gray"
                onClick={(e) => changeMenu(e, null)}
              >
                Отмена
              </button>

              {isSaving && <Loading />}
              {!isSaving && (
                <button
                  className="btn btn-yellow-border"
                  onClick={() => saveDevice(menu)}
                >
                  Сохранить
                </button>
              )}
            </div>

            {/* {(STATE.user.role === 'ADMIN' && item.user_id || STATE.user.role !== 'ADMIN') */}
            {STATE.user.role !== "ADMIN" && (
              <div
                className="button-area _between"
                onClick={() => deleteDevice(menu)}
              >
                <button className="btn btn-red">
                  {/* Отвязать {typeof item.user_id === 'undefined' || item.user_id === STATE.user.id ? 'свое' : 'чужое'} устройство */}
                  Отвязать свое устройство
                </button>
              </div>
            )}

            {STATE.user.role === "ADMIN" &&
              item.users &&
              item.users.map((usr) => {
                return (
                  <React.Fragment key={`${menu}${usr.id}`}>
                    <CopyToClipboard
                      text={usr.email}
                      onCopy={() =>
                        addToast(`Скопировано: ${usr.email}`, {
                          appearance: "info",
                          autoDismiss: true,
                        })
                      }
                    >
                      <div className="button-area _between">
                        <button className="btn btn-code">{usr.email}</button>
                      </div>
                    </CopyToClipboard>

                    <CopyToClipboard
                      text={`${usr.id} ${usr.name}`}
                      onCopy={() =>
                        addToast(`Скопировано: ${usr.id} ${usr.name}`, {
                          appearance: "info",
                          autoDismiss: true,
                        })
                      }
                    >
                      <div className="button-area _between">
                        <button className="btn btn-code">
                          [{usr.id}] {usr.name}
                        </button>
                      </div>
                    </CopyToClipboard>

                    <div
                      className="button-area _between"
                      onClick={() => deleteDeviceFrom(menu, usr.id)}
                    >
                      <button
                        className="btn btn-red"
                        style={{ marginTop: "5px", marginBottom: "0.5rem" }}
                      >
                        Отвязать у пользователя
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}

            {STATE.user.role === "ADMIN" && (
              <CopyToClipboard
                text={item.code}
                onCopy={() =>
                  addToast(`Код ${item.code} скопирован`, {
                    appearance: "info",
                    autoDismiss: true,
                  })
                }
              >
                <div
                  className="button-area _between"
                  style={{ marginTop: "5px" }}
                >
                  <button className="btn btn-code">{item.code}</button>
                </div>
              </CopyToClipboard>
            )}

            <div
              className="devices-dots"
              onClick={(event) => changeMenu(event, null)}
            >
              <Dots />
            </div>
          </div>
        )}
      </div>
    );
  });

  if (isLoading)
    return (
      <div style={{ height: "48px" }}>
        <Loading />
      </div>
    );

  if (!isLoading && redirect)
    return <Redirect to={`/device/${STATE.devices[0].id}`} />;

  if (!isLoading)
    return (
      <>
        <div className="devices-list">
          {STATE.devices.length === 0 && (
            <div className="error-message">
              <p className="error-message-text">Не удалось найти устройства</p>
            </div>
          )}
          {STATE.devices.length > 0 && devicesList}
        </div>

        <NewDevice />
      </>
    );
};

export default Devices;
