export default {
    1: '1 мин',
    2: '2 мин',
    3: '3 мин',
    4: '5 мин',
    5: '10 мин',
    6: '12 мин',
    7: '15 мин',
    8: '20 мин',
    9: '30 мин',
    10: '40 мин',
    11: '60 мин',
}