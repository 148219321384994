import React from 'react'
import "../../assets/styles/modal.sass"
import Modal from 'react-modal'
import silences from "../../helpers/silences"

const SilenceSelector = ({ isOpen, handleClose, setSilence, selected = null, exclude }) => {

  const itemList = silences.filter((_, index) => !exclude.includes(index)).map(item => {
      return (
        <div
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); select(item.id) }}
          className={`ringing-item${item.id === selected ? ' _selected' : ''}`}
          key={item.id}
        >
          <div className="ringing-name">{item.title}</div>
        </div>
      )
  })

  const select = (id) => {
    setSilence(id)
  }

  // console.log('exclude', exclude)

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={(e) => { e.preventDefault(); e.stopPropagation(); handleClose() }}
      shouldCloseOnOverlayClick={true}
      className="modal modal-ringing">

      <div className="block ringing-area">
        <div className="ringing-scroll">
          {itemList}
        </div>
      </div>
    </Modal>
  )
}

export default SilenceSelector
