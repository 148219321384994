import React from 'react'
import "../../assets/styles/modal.sass"
import Modal from 'react-modal'

const MelodySelector = ({ items, isOpen, setModal, modalIndex = null, setMelody, selected = null }) => {

  const itemList = items.map(item => {
    if (item.id !== 0) {
      return (
        <div
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); select(item.id) }}
          className={`ringing-item${item.id === parseInt(selected, 16) ? ' _selected' : ''}`}
          key={item.id}
        >
          <div className="ringing-name">{item.title}</div>
        </div>
      )
    } else {
      return (
        <div
          onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
          className='ringing-title'
          key={item.id}
        >
          <div className="ringing-name">{item.title}</div>
        </div>
      )
    }
  })

  const select = (id) => {
    const idHex = id.toString(16)

    setMelody(`${idHex.length > 1 ? idHex : `0${idHex}`}`, modalIndex)
    setModal(false, modalIndex)
  }

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={(e) => { e.preventDefault(); e.stopPropagation(); setModal(false, modalIndex) }}
      shouldCloseOnOverlayClick={true}
      className="modal modal-ringing">

      <div className="block ringing-area">
        <div className="ringing-scroll">
          {itemList}
        </div>
      </div>
    </Modal>
  )
}

export default MelodySelector
