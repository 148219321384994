import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import "moment/locale/ru";
import { ReactComponent as SelectIcon } from "../../../assets/images/select-icon.svg";
import { StateContext } from "../../../context/StateContext";
import melodies15 from "../../../helpers/melodies15";
import melodies255 from "../../../helpers/melodies255";
import MelodySelect from "../../../components/modal/melodySelect";
import IntervalSelector from "../../../components/modal/intervalSelect";
import SilenceSelector from "../../../components/modal/silenceSelect";
import Loading from "../../../components/loading/loading";
import Logs from "./Logs";
import Header from "../../../components/header/header";
import { fetchMelodiesByDevice } from "../../../helpers/fetchMelodies";

const Chimes = () => {
  const STATE = React.useContext(StateContext);

  const [hour1, setHour1] = useState(0);
  const [minute1, setMinute1] = useState(0);
  const [hour2, setHour2] = useState(0);
  const [minute2, setMinute2] = useState(0);

  const [sleep1Open, setSleep1Open] = useState(false);
  const [sleep2Open, setSleep2Open] = useState(false);

  const [status, setStatus] = useState(0);
  const [chimes, setChimes] = useState([]);
  const [silences, setSilences] = useState([]);
  const [timeChimeOpen, setTimeChimeOpen] = useState(false);

  const [selectedS, setSelectedS] = useState("S30");
  const [openIntervals, setOpenIntervals] = useState(false);

  const fetchingTitles = async () => {
    const titles = await fetchMelodiesByDevice(STATE.lastData.deviceId);

    let melodies = [];

    const srcMelodies =
      STATE.lastData.maxMelodies !== undefined ? melodies255 : melodies15;

    [
      ...srcMelodies.filter(
        (item) => item.id > 0 && item.id <= (STATE.lastData.maxMelodies ?? 15)
      ),
    ].forEach((item) => {
      if (item.id in titles) {
        melodies.push({ ...item, title: titles[item.id] });
      } else {
        melodies.push({ ...item });
      }
    });

    setModalItems(melodies);
  };

  useEffect(() => {
    // if (!!STATE.lastData && STATE.lastData.settings.version === 5) {
    if (!!STATE.lastData) {
      fetchingTitles();
    }
  }, [STATE.lastData]);

  const switchChimes = (e, index) => {
    e.stopPropagation();

    const newChimes = [...chimes];

    newChimes[index].status = newChimes[index].status === 1 ? 0 : 1;

    setChimes(newChimes);
  };

  const switchBeat = (e, index) => {
    e.stopPropagation();

    const newChimes = [...chimes];

    newChimes[index].beat = newChimes[index].beat === 1 ? 0 : 1;

    setChimes(newChimes);
  };

  const [modalOpen, setModalOpen] = useState([false, false, false]);
  const [modalItems, setModalItems] = useState([]);
  const [modalSilenceOpen, setModalSilenceOpen] = useState(false);
  const [selectedSilence, setSelectedSilence] = useState(null);

  useEffect(() => {
    setHour1(STATE.lastData.chimesSleep.hour1);
    setMinute1(STATE.lastData.chimesSleep.minute1);
    setHour2(STATE.lastData.chimesSleep.hour2);
    setMinute2(STATE.lastData.chimesSleep.minute2);
    setStatus(STATE.lastData.chimesSleep.status);

    const newChimes = [
      { ...STATE.lastData.chimesHalfHour },
      { ...STATE.lastData.chimesHour },
      { ...STATE.lastData.chimesTime },
    ];

    setChimes(newChimes);

    const newSilences = [];

    STATE.lastData.silences.forEach((item) => {
      if (item > 0) {
        if (item === 24) {
          newSilences.push(0);
        } else {
          newSilences.push(item);
        }
      }
    });

    setSilences(newSilences);

    // if (STATE.lastData.settings.version === 5) {
      fetchingTitles();
    // } else {
    //   setModalItems([...melodies15]);
    // }

    if (STATE.lastData?.settings?.version >= 3.6) {
      if (newChimes[0].s15 === true) setSelectedS("S15");
      if (newChimes[0].s30 === true) setSelectedS("S30");
    }
  }, []);

  const silenceFormat = (value) => {
    const a = `${value > 9 ? value : `0${value}`}`;

    return `${a}:00 - ${a}:59`;
  };

  const switchSleep = (e) => {
    e.stopPropagation();
    setStatus(status === 1 ? 0 : 1);
  };

  const setMelody = (value, index) => {
    const newChimes = [...chimes];
    newChimes[index].music = value;
    setChimes(newChimes);
  };

  const setModal = (value, index) => {
    const newModalOpen = [...modalOpen];
    newModalOpen[index] = value;
    setModalOpen(newModalOpen);
  };

  const setChimesHour = (value) => {
    const newChimes = [...chimes];
    newChimes[2].hour = value;
    setChimes(newChimes);
  };

  const setChimesMinute = (value) => {
    const newChimes = [...chimes];
    newChimes[2].minute = value;
    setChimes(newChimes);
  };

  const changeTimeChime = (value) => {
    setChimesHour(parseInt(value.format("HH"), 10));
    setChimesMinute(parseInt(value.format("mm"), 10));
  };

  const changeTime1 = (value) => {
    setHour1(parseInt(value.format("HH"), 10));
    setMinute1(parseInt(value.format("mm"), 10));
  };

  const changeTime2 = (value) => {
    setHour2(parseInt(value.format("HH"), 10));
    setMinute2(parseInt(value.format("mm"), 10));
  };

  let rings = [];
  let melodyTitles = [];
  let timeChime;
  let time1;
  let time2;

  if (chimes.length > 0) {
    chimes.forEach((chime) => {
      rings.push(chime.music);

      console.log("chime");
      modalItems.forEach((item) => {
        if (parseInt(item.id, 10) === parseInt(chime.music, 16))
          melodyTitles.push(item.title);
      });
    });

    timeChime = `${chimes[2].hour}:${chimes[2].minute}`;
    time1 = `${hour1}:${minute1}`;
    time2 = `${hour2}:${minute2}`;
  }

  const setSilence = (id) => {
    if (selectedSilence !== null) {
      const newSilences = [];
      silences.forEach((item, index) => {
        if (index !== selectedSilence) {
          newSilences.push(item);
        } else {
          newSilences.push(id);
        }
      });

      setSilences(newSilences);
    } else {
      const newSilences = silences;
      newSilences.push(id);
      setSilences(newSilences);
    }

    handleCloseSilence();
  };

  const handleCloseSilence = () => {
    setModalSilenceOpen(false);
    setSelectedSilence(null);
  };

  const deleteSilence = (deletedIndex) => {
    const newSilences = [];
    silences.forEach((item, index) => {
      if (index !== deletedIndex) newSilences.push(item);
    });
    setSilences(newSilences);
  };

  const save = () => {
    const chimesSleep = {
      status,
      hour1,
      hour2,
      minute1,
      minute2,
    };

    STATE.sendData({
      chimesSleep,
      chimesHalfHour: chimes[0],
      chimesHour: chimes[1],
      chimesTime: chimes[2],
      silences,
    });
  };

  useEffect(() => {
    if (chimes.length === 0) return;

    const newChimes = [...chimes];

    if (selectedS === "S30") {
      newChimes[0].s30 = true;
      newChimes[0].s15 = false;
    }

    if (selectedS === "S15") {
      newChimes[0].s30 = false;
      newChimes[0].s15 = true;
    }

    setChimes(newChimes);
  }, [selectedS]);

  const swithS = (action) => {
    const newChimes = [...chimes];

    if (action === "OFF") {
      newChimes[0].s30 = false;
      newChimes[0].s15 = false;
    } else if (action === "ON") {
      if (selectedS === "S30") newChimes[0].s30 = true;
      if (selectedS === "S15") newChimes[0].s15 = true;
    } else if (action === "S30") {
      newChimes[0].s30 = true;
      newChimes[0].s15 = false;
    } else if (action === "S15") {
      newChimes[0].s30 = false;
      newChimes[0].s15 = true;
    }

    setChimes(newChimes);
  };

  if (chimes.length === 0)
    return (
      <div className="main-area-field full-height">
        <div className="main-area full-height">
          <div className="page-area color-white no-p">
            <Header
              title={"Куранты"}
              backTitle="Пульт"
              backUrl={`/device/${STATE.subscribed.id}`}
            />

            <Logs />
          </div>
        </div>
      </div>
    );

  return (
    <div className="main-area-field full-height">
      <div className="main-area full-height">
        <div className="page-area color-white no-p">
          <Header
            title={"Куранты"}
            backTitle="Пульт"
            backUrl={`/device/${STATE.subscribed.id}`}
          />

          <Logs />

          <div className="page-area center-h color-white">
            <div className="chimes-content">
              <div className="chimes-list">
                <div
                  className={`chimes-item${
                    !!chimes[1].status ? " chimes-item--active" : ""
                  } timers-item icon-2`}
                >
                  <div className="chimes-time">
                    <div className="time">Каждый час</div>
                    <div className="switch-area">
                      <input
                        type="checkbox"
                        id="switch-1"
                        checked={!!chimes[1].status}
                        onChange={(e) => switchChimes(e, 1)}
                      />
                      <label
                        role="button"
                        tabIndex="0"
                        className="radio"
                        htmlFor="switch-1"
                      />
                    </div>
                  </div>

                  {STATE.lastData?.settings?.version >= 3.5 && (
                    <div className="time-time">
                      <span
                        className={`time-title${
                          !!chimes[1].beat ? " time-title--active" : ""
                        }`}
                      >
                        Отбивать время
                      </span>
                      <div className="switch-area">
                        <input
                          type="checkbox"
                          id="switchBeat-1"
                          checked={!!chimes[1].beat}
                          disabled={!chimes[1].status}
                          onChange={(e) => switchBeat(e, 1)}
                        />
                        <label
                          role="button"
                          tabIndex="0"
                          className="radio"
                          htmlFor="switchBeat-1"
                        />
                      </div>
                    </div>
                  )}

                  <div className="chimes-options">
                    <div
                      className="input-link chimes-options"
                      onClick={() => setModal(true, 1)}
                    >
                      <div className="input-container _no-mr">
                        <div className="input-area">
                          <input
                            type="text"
                            readOnly
                            value={melodyTitles[1]}
                          />
                          <SelectIcon className="select-icon _abs" />
                          <MelodySelect
                            isOpen={modalOpen[1]}
                            selected={rings[1]}
                            items={modalItems}
                            modalIndex={1}
                            setModal={setModal}
                            setMelody={setMelody}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`chimes-item${
                    !!chimes[0].status ||
                    chimes[0].s15 === true ||
                    chimes[0].s30 === true
                      ? " chimes-item--active"
                      : ""
                  } timers-item icon-3`}
                >
                  {STATE.lastData?.settings?.version < 3.6 && (
                    <div className="chimes-time">
                      <div className="time">Каждые полчаса</div>
                      <div className="switch-area">
                        <input
                          type="checkbox"
                          id="switch-2"
                          checked={!!chimes[0].status}
                          onChange={(e) => switchChimes(e, 0)}
                        />
                        <label
                          role="button"
                          tabIndex="0"
                          className="radio"
                          htmlFor="switch-2"
                        />
                      </div>
                    </div>
                  )}

                  {STATE.lastData?.settings?.version >= 3.6 && (
                    <div
                      className="chimes-time"
                      style={{ marginBottom: "7px" }}
                    >
                      <div
                        className={`time time__select${
                          chimes[0].s15 === true || chimes[0].s30 === true
                            ? " _active"
                            : ""
                        }`}
                        role="button"
                        tabIndex={0}
                        onClick={() => setOpenIntervals(true)}
                      >
                        {selectedS === "S30"
                          ? "Каждые 30 минут"
                          : "Каждые 15 минут"}

                        <SelectIcon className="select-icon _abs" />
                      </div>

                      <IntervalSelector
                        isOpen={openIntervals}
                        handleClose={() => setOpenIntervals(false)}
                        setInterval={setSelectedS}
                        selected={selectedS}
                      />

                      <div className="switch-area">
                        <input
                          type="checkbox"
                          id="switch-2"
                          checked={
                            chimes[0].s15 === true || chimes[0].s30 === true
                          }
                          onChange={() =>
                            chimes[0].s15 === true || chimes[0].s30 === true
                              ? swithS("OFF")
                              : swithS("ON")
                          }
                        />
                        <label
                          role="button"
                          tabIndex="0"
                          className="radio"
                          htmlFor="switch-2"
                        />
                      </div>
                    </div>
                  )}

                  {STATE.lastData?.settings?.version == 3.5 && (
                    <div className="time-time">
                      <span
                        className={`time-title${
                          !!chimes[0].beat ? " time-title--active" : ""
                        }`}
                      >
                        Отбивать время
                      </span>
                      <div className="switch-area">
                        <input
                          type="checkbox"
                          id="switchBeat-2"
                          checked={!!chimes[0].beat}
                          disabled={!chimes[0].status}
                          onChange={(e) => switchBeat(e, 0)}
                        />
                        <label
                          role="button"
                          tabIndex="0"
                          className="radio"
                          htmlFor="switchBeat-2"
                        />
                      </div>
                    </div>
                  )}

                  <div className="chimes-options">
                    <div
                      className="input-link chimes-options"
                      onClick={() => setModal(true, 0)}
                    >
                      <div className="input-container _no-mr">
                        <div className="input-area">
                          <input
                            type="text"
                            readOnly
                            value={melodyTitles[0]}
                          />
                          <SelectIcon className="select-icon _abs" />
                          <MelodySelect
                            isOpen={modalOpen[0]}
                            selected={rings[0]}
                            items={modalItems}
                            modalIndex={0}
                            setModal={setModal}
                            setMelody={setMelody}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`chimes-item${
                    !!chimes[2].status ? " chimes-item--active" : ""
                  } timers-item icon-5`}
                >
                  <div className="chimes-time">
                    <div className="time">В заданное время</div>
                    <div className="switch-area">
                      <input
                        type="checkbox"
                        id="switch-3"
                        checked={!!chimes[2].status}
                        onChange={(e) => switchChimes(e, 2)}
                      />
                      <label
                        className="radio"
                        role="button"
                        tabIndex="0"
                        htmlFor="switch-3"
                      />
                    </div>
                  </div>

                  {STATE.lastData?.settings?.version >= 3.5 && (
                    <div className="time-time">
                      <span
                        className={`time-title${
                          !!chimes[2].beat ? " time-title--active" : ""
                        }`}
                      >
                        Отбивать время
                      </span>
                      <div className="switch-area">
                        <input
                          type="checkbox"
                          id="switchBeat-3"
                          checked={!!chimes[2].beat}
                          disabled={!chimes[2].status}
                          onChange={(e) => switchBeat(e, 2)}
                        />
                        <label
                          role="button"
                          tabIndex="0"
                          className="radio"
                          htmlFor="switchBeat-3"
                        />
                      </div>
                    </div>
                  )}

                  <div className="chimes-options">
                    <div
                      className="input-link chimes-options"
                      onClick={() => setModal(true, 2)}
                    >
                      <div className="input-container _no-mr">
                        <div className="input-area">
                          <input
                            type="text"
                            readOnly
                            value={melodyTitles[2]}
                          />
                          <SelectIcon className="select-icon _abs" />
                          <MelodySelect
                            isOpen={modalOpen[2]}
                            selected={rings[2]}
                            items={modalItems}
                            modalIndex={2}
                            setModal={setModal}
                            setMelody={setMelody}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="time-settings-block">
                      <div className="custom-select-area">
                        <Datetime
                          className="time-only"
                          dateFormat={false}
                          timeFormat="HH:mm"
                          open={timeChimeOpen}
                          inputProps={{
                            readOnly: true,
                            onClick: () => {
                              setTimeChimeOpen(!timeChimeOpen);
                              setSleep1Open(false);
                              setSleep2Open(false);
                            },
                          }}
                          value={moment(timeChime, "HH:mm")}
                          onChange={(value) => changeTimeChime(value)}
                        />
                        <SelectIcon
                          className={`select-icon _abs${
                            timeChimeOpen ? " _rotate" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {STATE.lastData?.settings?.version >= 3.5 && (
                <div className="silence-mode">
                  <div className="mode-header">
                    <div className="text">Часы службы</div>
                  </div>
                  <div className="silence-items">
                    {silences.map((item, index) => {
                      return (
                        <div key={`s-${index}`} className="input-area">
                          <div
                            className="silence-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              setSelectedSilence(index);
                              setModalSilenceOpen(true);
                            }}
                          >
                            {silenceFormat(item)}
                          </div>
                          <div
                            role="button"
                            tabIndex={0}
                            className="delete-silence"
                            onClick={() => deleteSilence(index)}
                          >
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      );
                    })}

                    {silences.length < STATE.lastData.silences.length && (
                      <div className="input-area">
                        <button
                          type="button"
                          className="btn btn-yellow"
                          onClick={() => setModalSilenceOpen(true)}
                        >
                          Добавить
                        </button>
                      </div>
                    )}
                  </div>

                  <SilenceSelector
                    isOpen={modalSilenceOpen}
                    selected={silences[selectedSilence]}
                    modalIndex={selectedSilence}
                    handleClose={handleCloseSilence}
                    setSilence={setSilence}
                    exclude={silences.filter(
                      (item, index) => index !== selectedSilence
                    )}
                  />
                </div>
              )}

              <div className="sleep-mode">
                <div className="mode-header">
                  <div className="text">Режим сна</div>
                  <div className="switch-area">
                    <input
                      type="checkbox"
                      id="switch-5"
                      checked={status === 1 || status === "01"}
                      onChange={switchSleep}
                    />
                    <label htmlFor="switch-5" />
                  </div>
                </div>
                <div className="mode-time">
                  <div className="time-settings-block">
                    <span className="t">с</span>

                    <div className="custom-select-area">
                      <Datetime
                        className="time-only"
                        dateFormat={false}
                        timeFormat="HH:mm"
                        open={sleep1Open}
                        inputProps={{
                          readOnly: true,
                          onClick: () => {
                            setTimeChimeOpen(false);
                            setSleep2Open(false);
                            setSleep1Open(!sleep1Open);
                          },
                        }}
                        value={moment(time1, "HH:mm")}
                        onChange={(value) => changeTime1(value)}
                      />
                      <SelectIcon
                        className={`select-icon _abs${
                          sleep1Open ? " _rotate" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="time-settings-block">
                    <span className="t">по</span>

                    <div className="custom-select-area">
                      <Datetime
                        className="time-only"
                        dateFormat={false}
                        timeFormat="HH:mm"
                        open={sleep2Open}
                        inputProps={{
                          readOnly: true,
                          onClick: () => {
                            setTimeChimeOpen(false);
                            setSleep1Open(false);
                            setSleep2Open(!sleep2Open);
                          },
                        }}
                        value={moment(time2, "HH:mm")}
                        onChange={(value) => changeTime2(value)}
                      />
                      <SelectIcon
                        className={`select-icon _abs${
                          sleep2Open ? " _rotate" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="button-area _center">
                <button
                  className="btn btn-yellow chimes-save"
                  onClick={() => save()}
                >
                  {!STATE.isSending && "СОХРАНИТЬ"}
                  {STATE.isSending && <Loading />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chimes;
