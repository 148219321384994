import React, { useContext } from "react";
import "../../assets/styles/modal.sass";
import Modal from "react-modal";
import { MainContext } from "../../context/MainContext";
import { ReactComponent as SelectIcon } from "../../assets/images/select-icon.svg";

const ModalChoiseRinging = () => {
  const [mainState, mainDispatch] = useContext(MainContext);

  const setIsClose = () => {
    mainDispatch({ type: "OPEN_MODAL", payload: false });
  };
  return (
    <Modal
      isOpen={mainState.modal}
      ariaHideApp={false}
      className="modal modal-ringing"
    >
      <div className="modal-title-block">
        <div className="modal-title">Благовест</div>
      </div>
      <div className="modal-time">
        <div className="time-name">время работы</div>
        <div className="time-settings">
          <div className="time-settings-block">
            <span className="t">с</span>
            <div className="custom-select-area">
              <div className="custom-select">
                <span>12.00</span>
                <SelectIcon className="select-icon" />
              </div>
              <div className="custom-select-result">
                <div className="custom-select-options">
                  <span>12.00</span>
                  <span>12.00</span>
                  <span>12.00</span>
                </div>
              </div>
            </div>
          </div>
          <div className="time-settings-block">
            <span className="t">по</span>
            <div className="custom-select-area _open">
              <div className="custom-select">
                <span>12.00</span>
                <SelectIcon className="select-icon" />
              </div>
              <div className="custom-select-result">
                <div className="custom-select-options">
                  <span>12.00</span>
                  <span>12.00</span>
                  <span>12.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-time">
        <div className="time-name">режим сна</div>
        <div className="time-settings">
          <div className="time-settings-block">
            <span className="t">с</span>
            <div className="custom-select-area">
              <div className="custom-select">
                <span>12.00</span>
                <SelectIcon className="select-icon" />
              </div>
              <div className="custom-select-result">
                <div className="custom-select-options">
                  <span>12.00</span>
                  <span>12.00</span>
                  <span>12.00</span>
                </div>
              </div>
            </div>
          </div>
          <div className="time-settings-block">
            <span className="t">по</span>
            <div className="custom-select-area">
              <div className="custom-select">
                <span>12.00</span>
                <SelectIcon className="select-icon" />
              </div>
              <div className="custom-select-result">
                <div className="custom-select-options">
                  <span>12.00</span>
                  <span>12.00</span>
                  <span>12.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-area _between">
        <button className="btn btn-gray" onClick={setIsClose}>
          ОТМЕНА
        </button>
        <button className="btn btn-yellow-border">сохранить</button>
      </div>
    </Modal>
  );
};

export default ModalChoiseRinging;
