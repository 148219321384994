import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../../../context/StateContext";
import Loading from "../../../components/loading/loading";
import melodies255 from "../../../helpers/melodies255";
import melodies15 from "../../../helpers/melodies15";
import Header from "../../../components/header/header";
import MelodyRename from "../../../components/modal/melodyRename";
import { fetchMelodiesByDevice } from "../../../helpers/fetchMelodies";

const Melodies = () => {
  const STATE = useContext(StateContext);
  const [melodies, setMelodies] = useState([]);
  const [maxMelodies, setMaxMelodies] = useState([]);
  const [isOpenRename, setIsOpenRename] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [renamingId, setRenamingId] = useState(0);

  const submit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!!STATE.lastData) {
      fetchingTitles();
    }
  }, []);

  const fetchingTitles = async () => {
    setIsFetching(true);
    const titles = await fetchMelodiesByDevice(STATE.lastData.deviceId);

    setMaxMelodies(STATE.lastData.maxMelodies);
    let melodies = [];

    const srcMelodies =
      STATE.lastData.maxMelodies !== undefined ? melodies255 : melodies15;

    [
      ...srcMelodies.filter(
        (item) => item.id > 0 && item.id <= (STATE.lastData.maxMelodies ?? 15)
      ),
    ].forEach((item) => {
      if (item.id in titles) {
        melodies.push({ ...item, title: titles[item.id] });
      } else {
        melodies.push({ ...item });
      }
    });

    setMelodies(melodies);
    setIsFetching(false);
  };

  const openRename = (id) => {
    setRenamingId(id);
    setIsOpenRename(true);
  };

  const handleClose = () => {
    setRenamingId(0);
    setIsOpenRename(false);
  };

  const handleUpdate = () => {
    fetchingTitles();
  };

  let melodiesList;

  if (!isFetching)
    melodiesList = melodies.map((melody) => {
      return (
        <div
          key={melody.id}
          type="button"
          className="ringing-item"
          onSubmit={submit}
          style={{ margin: 0 }}
        >
          <button
            type="button"
            className="ringing-name"
            onClick={() => openRename(melody.id)}
          >
            {melody.title}
          </button>
        </div>
      );
    });

  const saveCount = () => {
    const data = { maxMelodies };

    STATE.sendData(data);
  };

  return (
    <div className="main-area-field full-height">
      <div className="main-area full-height">
        <div className="page-area no-p">
          <Header
            title={"Названия звонов"}
            backTitle="Настройки"
            backUrl={`/device/${STATE.subscribed.id}/settings`}
          />

          <div className="page-area center-h">
            <div className="content">
              <div className="block trans-block">
                {STATE.lastData.maxMelodies !== undefined && (
                  <div className="melodies-settings">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="general-title"
                        style={{ marginBottom: "0" }}
                      >
                        Кол-во звонов
                      </div>

                      <div className="switch-area" style={{ width: "50%" }}>
                        <input
                          className="input-title"
                          type="number"
                          readOnly
                          min={0}
                          max={255}
                          style={{ textAlign: "center" }}
                          value={maxMelodies}
                          onChange={(e) =>
                            setMaxMelodies(e.target.value.trim())
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="melody-count-buttons">
                    {!STATE.isSending && (
                      <button
                        onClick={() => saveCount()}
                        type="button"
                        style={{
                          width: "100%",
                          marginBottom: 0,
                          marginTop: "12px",
                        }}
                        className="btn btn-save-title"
                      >
                        Сохранить кол-во
                      </button>
                    )}

                    {STATE.isSending && (
                      <div
                        style={{
                          width: "100%",
                          marginTop: "12px",
                        }}
                      >
                        <Loading />
                      </div>
                    )}
                  </div> */}
                  </div>
                )}

                <div className="melodies-settings">
                  <div className="general-title">Выберите звон</div>
                  <div>{melodiesList}</div>
                  {isFetching && (
                    <div style={{ height: "20px" }}>
                      <Loading />
                    </div>
                  )}
                </div>

                {renamingId > 0 && (
                  <MelodyRename
                    deviceId={STATE.lastData.deviceId}
                    isOpen={isOpenRename}
                    handleClose={handleClose}
                    title={melodies[renamingId - 1].title}
                    melodyId={renamingId}
                    handleUpdate={handleUpdate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Melodies;
