import React from "react";
import "../../assets/styles/modal.sass";
import Modal from "react-modal";
import zones from "../../helpers/timezones";

const TimeZoneSelector = ({
  isOpen,
  handleClose,
  setZone,
  selected = null,
}) => {
  const itemList = Object.keys(zones).map((id) => {
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          select(id);
        }}
        className={`ringing-item${+id === +selected ? " _selected" : ""}`}
        key={id}
      >
        <div className="ringing-name">{zones[id]}</div>
      </div>
    );
  });

  const select = (id) => {
    setZone(+id);
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleClose();
      }}
      shouldCloseOnOverlayClick={true}
      className="modal modal-ringing"
    >
      <div className="block ringing-area">
        <div className="ringing-scroll">{itemList}</div>
      </div>
    </Modal>
  );
};

export default TimeZoneSelector;
