import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './AppRouter'
import './assets/styles/reset.sass'
import './assets/styles/main.sass'
import './assets/styles/datetime.sass'
import './assets/styles/home-media.sass'
import axios from 'axios'
import { MainProvider } from "./context/MainContext";
import { StateContextProvider } from './context/StateContext'
import 'react-datetime/css/react-datetime.css'
import { ToastProvider } from 'react-toast-notifications'
import { baseUrl } from './utils/helpers'

axios.defaults.baseURL = baseUrl

const App = () => {
  return (
    <ToastProvider autoDismissTimeout={3000}>
      <StateContextProvider>
        <MainProvider>
          <AppRouter />
        </MainProvider>
      </StateContextProvider>
    </ToastProvider>
  )
}

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.querySelector('#root'))
