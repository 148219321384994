import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as EyeH } from "../../assets/images/eye-hidden.svg";
import { ReactComponent as EyeV } from "../../assets/images/eye-visib.svg";
import Header from "../../components/header/header";
import api from "./api";
import axios from "axios";
import { StateContext } from "../../context/StateContext";
import MetaTags from "react-meta-tags";

const Enter = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [passwordSee, setPasswordSee] = useState(false);
  const STATE = React.useContext(StateContext);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors({});

    try {
      const res = await api.login({ email, password });

      localStorage.setItem("token", res.data.user.token);
      localStorage.removeItem("previous");
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.user.token}`;
      STATE.setRedirectToOnly(true);
      STATE.setUser(res.data.user);
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error) setErrors(error.response.data.error);
        if (error.response.data.message)
          setErrors({ message: error.response.data.message });
      } else {
        setErrors({ message: "Что-то пошло не так" });
      }
    }
  };

  return (
    <div className="main-area-field full-height">
      <MetaTags>
        <title>Золотой звон | Вход</title>
        <meta
          name="description"
          content="Войдите в личный кабинет «Золотой звон»"
        />
        <meta property="og:title" content="Золотой звон | Вход" />
      </MetaTags>

      <div className="main-area full-height">
        <div className="page-area center-h ">
          {/* <Link
            to="/login-or-signup"
            className="title-block under-title under-title--home"
          >
            <BackIcon className="icon" />
            <div className="title">Назад</div>
          </Link> */}
          <Header
            title={"Вход"}
            backTitle={"Назад"}
            backUrl={"/login-or-signup"}
          />

          <div className="center-style">
            <form onSubmit={onSubmit} className="enter-form-area">
              <div className="title-form-block">
                <div className="title">Вход в личный кабинет</div>
              </div>
              <div className="input-container">
                <div
                  className={`input-area${errors.email ? " input-error" : ""}`}
                >
                  <input
                    type="text"
                    value={email}
                    name="email"
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value.trim())}
                  />
                </div>
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
              <div className="input-container">
                <div
                  className={`input-area${
                    errors.password ? " input-error" : ""
                  }`}
                >
                  <input
                    type={passwordSee ? "text" : "password"}
                    value={password}
                    name="password"
                    placeholder="Пароль"
                    onChange={(e) => setPassword(e.target.value.trim())}
                  />
                  <button
                    type="button"
                    className="form-eye"
                    onClick={(e) => setPasswordSee(!passwordSee)}
                  >
                    {passwordSee ? <EyeV /> : <EyeH />}
                  </button>
                </div>
                {errors.password && (
                  <div className="error">{errors.password}</div>
                )}
                {errors.message && (
                  <div className="error">{errors.message}</div>
                )}
              </div>
              <div className="button-area _between">
                <Link to="/reset-password" className="forgot-password">
                  Забыли пароль?
                </Link>

                <button type="submit" className="btn btn-yellow btn-enter">
                  Войти
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enter;
