import axios from 'axios'

export default {
    getDevices: () => axios.get('/devices'),
    getDevice: (id) => axios.get(`/devices/${id}`),
    postDevice: (data) => axios.post('/devices', data),
    generateDevice: () => axios.post('/devices/generate', ),
    saveDevice: (id, data) => axios.post(`/devices/${id}`, data),
    deleteDevice: (id) => axios.delete(`/devices/${id}`),
    deleteDeviceFrom: (data) => axios.post('/devices/delete_from/', data),
}