import React from 'react'
import Img1 from "../../../assets/images/landing/s11.jpg"
import Img2 from "../../../assets/images/landing/s22.jpg"
import Img3 from "../../../assets/images/landing/s33.jpg"
import Img4 from "../../../assets/images/landing/s44.jpg"
import Slider from "react-slick"

const Application = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  return (
    <div className="container-area-field advantages-fon">
      <div className="container-main-area big-container">
        <div className="application-area advantages-area">
          <div className="title-home title-block top-line">
            <h2 className="title">Приложение</h2>
          </div>
          <div className="title-home title-block">
            <div className="title-sub">
              Управляйте системой через наше приложение как через Интернет, так
              и по Bluetooth
            </div>
          </div>
          <div className="slider slider-advantages slider-application">
            <Slider {...settings}>
              <div className="slide-app-block">
                <img src={Img1} alt="" />
                <div className="app-text">
                  Включение звона
                  <br />в реальном времени
                </div>
              </div>

              <div className="slide-app-block">
                <img src={Img2} alt="" />
                <div className="app-text">
                  Настройка работы
                  <br />
                  таймеров
                </div>
              </div>

              <div className="slide-app-block">
                <img src={Img3} alt="" />
                <div className="app-text">
                  Настройка работы
                  <br />в режиме "Куранты"
                </div>
              </div>

              <div className="slide-app-block">
                <img src={Img4} alt="" />
                <div className="app-text">
                  Индивидуальные
                  <br />
                  настройки приводов
                </div>
              </div>
            </Slider>
          </div>
          {/*<div className="button-area _center">*/}
          {/*  <HashLink*/}
          {/*    to="/#contact"*/}
          {/*    smooth="true" className="btn btn-blue">*/}
          {/*    Узнать больше*/}
          {/*  </HashLink>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default Application;
