import React from 'react'
import "../../assets/styles/modal.sass"
import Modal from 'react-modal'

const BellSettingsSelector = ({ isOpen, handleClose, setValue, selected = 0, items }) => {

  const itemList = Object.keys(items).map((key) => {
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          select(key);
        }}
        className={`ringing-item${+key === selected ? " _selected" : ""}`}
        key={key}
      >
        <div className="ringing-name">{items[key]}</div>
      </div>
    );
  });

  const select = (id) => {
    setValue(+id);
    handleClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={(e) => { e.preventDefault(); e.stopPropagation(); handleClose() }}
      shouldCloseOnOverlayClick={true}
      className="modal modal-ringing">

      <div className="block ringing-area">
        <div className="ringing-scroll">
          {itemList}
        </div>
      </div>
    </Modal>
  )
}

export default BellSettingsSelector;
