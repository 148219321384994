export default {
  1: "1 сек",
  2: "1,5 сек",
  3: "2 сек",
  4: "2,5 сек",
  5: "3 сек",
  6: "3,5 сек",
  7: "4 сек",
  8: "4,5 сек",
  9: "5 сек",
  10: "6 сек",
  11: "7 сек",
  12: "8 сек",
  13: "10 сек",
  14: "12 сек",
  15: "15 сек",
};