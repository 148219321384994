import React, { createContext, useReducer } from "react";

const initialState = {
  modal: false,
  modalAgreement: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return { ...state, modal: action.payload };
    case "OPEN_MODAL_AGREEMENT":
      return { ...state, modalAgreement: action.payload };
    default:
      return state;
  }
};

export const MainContext = createContext();

export const MainProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
