import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import api from "./api";
import { ReactComponent as EyeH } from "../../assets/images/eye-hidden.svg";
import { ReactComponent as EyeV } from "../../assets/images/eye-visib.svg";
import Header from "../../components/header/header";
import MetaTags from "react-meta-tags";

const Registration = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [errors, setErrors] = useState({});
  const [isSuccessfullSubmit, setIsSuccessfullSubmit] = useState(false);
  const [passwordSee, setPasswordSee] = useState(false);
  const [passwordAgainSee, setPasswordAgainSee] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors({});

    try {
      await api.signup({ name, email, password, passwordAgain });
      setIsSuccessfullSubmit(true);
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error) setErrors(error.response.data.error);
        if (error.response.data.message)
          setErrors({ message: error.response.data.message });
      } else {
        setErrors({ message: "Что-то пошло не так" });
      }
    }
  };

  if (isSuccessfullSubmit) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="main-area-field full-height">
      <MetaTags>
        <title>Золотой звон | Регистрация в системе</title>
        <meta
          name="description"
          content="Регистрация в системе «Золотой звон»"
        />
        <meta
          property="og:title"
          content="Золотой звон | Регистрация в системе"
        />
      </MetaTags>

      <div className="main-area full-height">
        <div className="page-area center-h">
          <Header
            title={"Регистрация"}
            backTitle={"Назад"}
            backUrl={"/login-or-signup"}
          />

          <div className="center-style">
            <form onSubmit={onSubmit} className="enter-form-area">
              <div className="title-form-block">
                <div className="title">Регистрация в системе</div>
              </div>
              <div className="input-container input-full-name">
                <div
                  className={`input-area${errors.name ? " input-error" : ""}`}
                >
                  <input
                    type="text"
                    value={name}
                    name="name"
                    placeholder="Фио"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                {errors.name && <div className="error">{errors.name}</div>}
              </div>
              <div className="input-container">
                <div
                  className={`input-area${errors.email ? " input-error" : ""}`}
                >
                  <input
                    type="text"
                    value={email}
                    name="email"
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
              <div className="input-container">
                <div
                  className={`input-area${
                    errors.password ? " input-error" : ""
                  }`}
                >
                  <input
                    type={passwordSee ? "text" : "password"}
                    value={password}
                    name="password"
                    placeholder="Пароль"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="form-eye"
                    onClick={(e) => setPasswordSee(!passwordSee)}
                  >
                    {passwordSee ? <EyeV /> : <EyeH />}
                  </div>
                </div>
                {errors.password && (
                  <div className="error">{errors.password}</div>
                )}
              </div>
              <div className="input-container">
                <div
                  className={`input-area${
                    errors.passwordAgain ? " input-error" : ""
                  }`}
                >
                  <input
                    type={passwordAgainSee ? "text" : "password"}
                    value={passwordAgain}
                    name="password_again"
                    placeholder="Повторите пароль"
                    onChange={(e) => setPasswordAgain(e.target.value)}
                  />
                  <div
                    className="form-eye"
                    onClick={(e) => setPasswordAgainSee(!passwordAgainSee)}
                  >
                    {passwordAgainSee ? <EyeV /> : <EyeH />}
                  </div>
                </div>
                {errors.passwordAgain && (
                  <div className="error">{errors.passwordAgain}</div>
                )}
              </div>
              {/* <div className="input-container input-phone">
              <div className="input-area">
                <input type="text" placeholder="ТЕЛЕФОН"/>
              </div>
            </div>
            <div className="button-area _center">
              <button className="btn btn-border">отправить код
                <Arrows className="code-icon"/>
              </button>
            </div>
            <div className="input-container code">
              <div className="input-area">
                <input type="text" placeholder="КОД ПОДТВЕРЖДЕНИЯ"/>
              </div>
            </div> */}

              {/* <div className="id-device">
              ID устройства “Электронный звонарь”
            </div> */}
              <div className="input-container  code-device">
                {/* <div className="input-area">
                <input type="text" placeholder=""/>
              </div> */}
                {errors.message && (
                  <div className="error">{errors.message}</div>
                )}
              </div>
              <div className="button-area _center">
                <button type="submit" className="btn btn-yellow ">
                  Зарегистрироваться
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
