import React, { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'
import api from './api'
import { StateContext } from "../../context/StateContext"

const GenerateDevice = () => {
  const STATE = useContext(StateContext)
  const { addToast } = useToasts()

  const generate = async () => {
    try {
      const isOk = window.confirm('Содать пустое устройство?')

      if (isOk) {
        const device = await api.generateDevice()

        STATE.setDevices([...STATE.devices, device.data.device])

        addToast(`Устройство ${device.data.device.id} создано`, {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    } catch (error) {
      console.log('error', error)

      addToast('Что-то пошло не так', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  return (
    <div className='new-devices _open'>
      <div className="button-area">
        <button
          onClick={() => generate()}
          className='btn btn-yellow-border'
          style={{ width: '100%' }}>
            Создать устройство
        </button>
      </div>
    </div>
  )
}

export default GenerateDevice