import axios from "axios";

export const fetchMelodies = async () => {
  let titles = {};

  const result = await axios.get("/melodies");
  if (result?.data?.melodies && result?.data?.melodies.length > 0) {
    result.data.melodies.forEach((item) => {
      titles[item.melody_id] = item.title;
    });
  }

  return titles;
};

export const fetchMelodiesByDevice = async (deviceId) => {
  let titles = {};

  const result = await axios.get(`/device_melodies/${deviceId}`);
  if (
    result?.data?.device_melodies &&
    result?.data?.device_melodies.length > 0
  ) {
    result.data.device_melodies.forEach((item) => {
      titles[item.melody_id] = item.title;
    });
  }

  return titles;
};