import React, { createContext, useState, useEffect } from 'react'
import io from 'socket.io-client'

// const ADDR = 'http://localhost:8000'
const ADDR = 'https://vm-59d571df.na4u.ru:443'
let socket = null

export const StateContext = createContext()

export const StateContextProvider = ({ children }) => {
    const [user, setUser] = useState(false)
    const [tab, setTab] = useState('main')
    const [isGlobalLoading, setIsGlobalLoading] = useState(true)
    const [isConnecting, setIsConnecting] = useState(true)
    const [isSending, setIsSending] = useState(false)
    const [subscribed, setSubscribed] = useState(null)
    const [devices, setDevices] = useState([])
    const [lastData, setLastData] = useState(null)
    const [redirectToOnly, setRedirectToOnly] = useState(true)

    useEffect(() => {
        if (subscribed !== null) {
            connectToSocket();
        } else {
            if (socket !== null) {
                socket.removeAllListeners();
                socket.disconnect();
                setTab("main");
                setIsConnecting(true);
            }
        }
    }, [subscribed]);

    useEffect(() => {
        if (!!lastData && isSending) {
            setIsSending(false)
        }
    }, [lastData])

    const connectToSocket = async () => {
        const token = localStorage.getItem("token");

        socket = io(ADDR, {
          transports: ["polling"],
          extraHeaders: {
            token
          },
        });

        socket.on("welcome", message => {
            console.log("[WELCOME]", message)

            socket.emit("ONE_DEVICE_REQUEST_FROM_FRONT", subscribed.id);
        })

        socket.on(`ONE_DEVICE_FROM_SOCK_${subscribed.id}`, (device) => {
            console.log(`ONE_DEVICE_FROM_SOCK_${subscribed.id}`, device);
            fetchDevice(device);
        });

        socket.on(`SIGNAL_${subscribed.id}`, (data) => {
            console.log(`[SIGNAL_${subscribed.id}]`, data);
            setLastData(data);
        });

        socket.on(`DISCONNECTED_${subscribed.id}`, (data) => {
            console.log(`[DISCONNECTED_${subscribed.id}]`, data);
            setLastData(null);
        });

        socket.on('connect_error', (error) => {
            console.log("error", error.message);
        })
    }

    const fetchDevice = async (device) => {
        if (device !== null) {
            if (isConnecting) setLastData(device.data)
        } else {
            setLastData(null)
        }

        setIsConnecting(false)
    }

    const sendData = (data) => {
        console.log("sendData", {
          deviceId: subscribed.id,
          version: lastData.settings.version,
          data,
          user,
        });

        // return;

        socket.emit("SAVE_DATA_FROM_FRONT", {
            deviceId: subscribed.id,
            version: lastData.settings.version,
            data,
            user,
        });

        setIsSending(true)
    }

    const changeDevice = (id, data) => {
        const newDevices = [...devices]

        devices.forEach((item, index) => {
            if (item.id === id) {
                newDevices[index].title = data.title
                newDevices[index].info = data.info
            }
        })

        setDevices(newDevices)
    }

    const deleteDevice = (id) => {
        const newDevices = []

        devices.forEach(item => {
            if (item.id !== id) {
                newDevices.push(item)
            }
        })

        setDevices(newDevices)
    }

    return (
      <StateContext.Provider
        value={{
          user,
          setUser,
          isGlobalLoading,
          setIsGlobalLoading,
          devices,
          setDevices,
          subscribed,
          setSubscribed,
          connectToSocket,
          sendData,
          lastData,
          setLastData,
          isConnecting,
          setIsConnecting,
          tab,
          setTab,
          isSending,
          changeDevice,
          deleteDevice,
          redirectToOnly,
          setRedirectToOnly
        }}
      >
        {children}
      </StateContext.Provider>
    );
}
