import React, { useContext } from "react";
import axios from "axios";
import "./header.sass";
import { ReactComponent as ExitIcon } from "../../assets/images/exit.svg";
import { Link } from "react-router-dom";
import { StateContext } from "../../context/StateContext";
import { ReactComponent as BackIcon } from "../../assets/images/4571914521627270250.svg";

const Header = ({ title, backTitle = '', backUrl="/" }) => {
  const STATE = useContext(StateContext);

  const logout = async () => {
    STATE.setIsGlobalLoading(true);

    localStorage.removeItem("token");
    localStorage.removeItem("previous");
    axios.defaults.headers.common["Authorization"] = null;
    STATE.setUser(null);
    STATE.setSubscribed(null);
    setTimeout(STATE.setIsGlobalLoading, 500, false);
  };

  const showExit = !window.location.pathname.includes("device");

  return (
    <div className="header">
      <div className="header-content">
        <div className="header-nav">
          {backTitle && (
            <Link to={backUrl} className="back--nav">
              <BackIcon className="back--nav__img" />
            </Link>
          )}
          {/* {backTitle && (
            <Link to={backUrl} className="title-block under-title">
              <BackIcon className="icon" />
              <div className="title">{backTitle}</div>
            </Link>
          )} */}
        </div>

        <div className="header-title">{title}</div>

        {!!STATE.user && (
          <div className="header-icon">
            {showExit && (
              <button
                type="button"
                className="header-icon__button"
                onClick={() => logout()}
              >
                <ExitIcon />
              </button>
            )}
          </div>
        )}

        {!STATE.user && <div className="header-icon"></div>}
      </div>
    </div>
  );
};

export default Header;
