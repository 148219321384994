import React, { useEffect, useState, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as NoSignalIcon } from "../../assets/images/no-signal.svg"
import { ReactComponent as BackArrow } from "../../assets/images/back-arrow.svg"
import { ReactComponent as NotFoundIcon } from "../../assets/images/not-found.svg"
import Loading from '../../components/loading/loading'
import Logs from './components/Logs'
import { StateContext } from '../../context/StateContext'
import api from '../cabinet/api'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'
import 'moment/locale/ru'

import Main from './components/Main'
import Chimes from './components/Chimes'
import Timers from './components/Timers'
import Settings from './components/Settings'
import Header from '../../components/header/header'

const Signal = () => {
  let { id } = useParams()
  let { tab } = useParams()
  let { settings } = useParams()
  const [device, setDevice] = useState(null)
  const STATE = useContext(StateContext)
  const [restricted, setRestricted] = useState(false)
  const [waitingForUpdate, setWaitingForUpdate] = useState(false)
  const { addToast, removeToast } = useToasts()
  const [toastId, setToastId] = useState(null)
  const [updateToastId, setUpdateToastId] = useState(null)

  const fetchDevice = async () => {
    try {
      const device = await api.getDevice(id)
      setDevice(device.data.device)

      if (device.data.device && device.data.device.last_update !== null) setWaitingForUpdate(true)

      STATE.setSubscribed({ id })
      localStorage.setItem("previous", id);

      STATE.setTab(typeof tab === 'undefined' ? 'main' : tab)
    } catch (error) {
      setRestricted(true)

      console.log('error', error)
    }
  }

  useEffect(() => {
    try {
      STATE.setRedirectToOnly(false)
      setRestricted(false)
      STATE.setLastData(null)

      fetchDevice()
    } catch (error) {
      console.log('error', error)
    }

    return () => {
      STATE.setSubscribed(null);
    }
  }, [])

  useEffect(() => {
    STATE.setTab(typeof tab === 'undefined' ? 'main' : tab)
  }, [tab])

  useEffect(() => {
    if (device && !STATE.lastData) {
      api.getDevice(id).then(data => {
        setDevice(data.data.device)
      })
    }

    if (!!STATE.lastData) {
      if (STATE.lastData.alarm === 1 && !toastId) {
        addToast('Включен аварийный режим', {
          appearance: 'error',
          autoDismiss: false,
          onDismiss: () => setToastId(null)
        }, id => {
          setToastId(id)
        })
      } else if (STATE.lastData.alarm === 0 && !!toastId) {
        removeToast(toastId)
        setToastId(id)
      }

      if (waitingForUpdate) {
        setWaitingForUpdate(false)

        if (STATE.lastData.loadStatus === 0) {
          if (!updateToastId) {
            addToast('Звон успешно обновлен', {
              appearance: '',
              autoDismiss: false,
              onDismiss: () => setUpdateToastId(null)
            }, id => {
              setUpdateToastId(id)
            })
          }
        } else {
          if (!updateToastId) {
            addToast('Ошибка при обновлении звона', {
              appearance: 'error',
              autoDismiss: false,
              onDismiss: () => setUpdateToastId(null)
            }, id => {
              setUpdateToastId(id)
            })
          }
        }
      }
    }
  }, [STATE.lastData])

  useEffect(() => {
    if (!!device && !!device.last_update) setWaitingForUpdate(true)
  }, [device])

  let d
  let m
  let y

  let time

  if (device) {
    d = device.last_online.substr(8, 2)
    m = device.last_online.substr(5, 2)
    y = device.last_online.substr(0, 4)
    time = device.last_online.substr(11, 8)
  }

  if (STATE.isConnecting && !restricted) return (
    <div className="main-area-field full-height no-signal-area">
      <div className="main-area full-height">
        <div className="page-area center-h">
          <Header
            title={"Пульт"}
            backTitle={"Устройства"}
            backUrl={"/"}
          />

          <Loading />
        </div>
      </div>
    </div>
  );

  if (restricted) return (
    <div className="main-area-field full-height">
      <div className="main-area full-height">
        <div className="page-area center-h">
          <Header
            title={"Пульт"}
            backTitle={"Устройства"}
            backUrl={"/"}
          />

          <div className="back-link-block">
            <Link to="/" className="back-link">
              <BackArrow className="back-icon" />
              <div className="back-text">На главную</div>
            </Link>
            <div className="not-found-text">
              Страница не найдена
              <br /> или у вас нет доступа
            </div>
            <NotFoundIcon className="not-found-icon" />
            <div className="not-found-info">
              Попробуйте перезагрузить страницу
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (!STATE.isConnecting && !STATE.lastData) return (
    <div className="main-area-field full-height no-signal-area">
      <div className="main-area full-height">
        <div className="page-area center-h _no-signal">
          <Header title={"Пульт"} backTitle={"Устройства"} backUrl={"/"} />

          <Logs />

          <NoSignalIcon />

          {device && device.last_online && (
            <div className="signal-info">
              Последний выход на связь
              <br />
              <span>{`${time} ${d}.${m}.${y}`}</span>
            </div>
          )}

          {device &&
            device.last_update &&
            moment().diff(moment(device.last_update).add(-3, "hours")) / 60000 <
              5 && (
              <div className="signal-bold">
                <span>Подождите, идет обновление устройства</span>
              </div>
            )}

          {((device &&
            device.last_update &&
            moment().diff(moment(device.last_update).add(-3, "hours")) /
              60000 >=
              5) ||
            !(device && device.last_update)) && (
            <div className="signal-bold">
              <span>Нет соединения</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (!STATE.isConnecting && !!STATE.lastData && typeof tab === 'undefined') return <Main />
  if (!STATE.isConnecting && !!STATE.lastData && tab === 'timers') return <Timers />
  if (!STATE.isConnecting && !!STATE.lastData && tab === 'chimes') return <Chimes />
  if (!STATE.isConnecting && !!STATE.lastData && tab === 'settings') return <Settings tab={settings} />
}

export default Signal
