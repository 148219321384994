import React, { useContext } from 'react'
import { MainContext } from '../../context/MainContext'
import Modal from 'react-modal'
import { ReactComponent as Close } from '../../assets/images/close.svg'

const ModalAgreement = () => {
  const [mainState, mainDispatch] = useContext(MainContext)

  const setIsClose = () => {
    mainDispatch({ type: 'OPEN_MODAL_AGREEMENT', payload: false })
  }

  return (
    <Modal
      isOpen={mainState.modalAgreement}
      ariaHideApp={false}
      className='modal modal-agreement'>
      <div className='modal-close' onClick={setIsClose}>
        <Close />
      </div>
      <div className='modal-title-block'>
        <div className='modal-title'>
          Политика конфиденциальности
        </div>
      </div>
      <div className='modal-text'>
        Федеральный закон РФ № 152-ФЗ «О персональных данных» — федеральный закон,
        регулирующий деятельность по обработке (использованию) персональных данных.
       <br /><br />
       1. Использование информации
       <br /><br />
       Мы используем персонифицированную информацию конкретного посетителя сайта
       исключительно для обеспечения ему качественного оказания услуг и их учета.
       Мы не раскрываем персонифицированных данных одних посетителей сайта другим посетителям сайта.
       <br /><br />
       Мы никогда не публикуем персонифицированную информацию в открытом доступе
       и не передаем ее третьим лицам. Исключением являются лишь ситуации, когда предоставление
       такой информации уполномоченным государственным органам предписано действующим
       законодательством Российской Федерации.
       <br /><br />
       Мы публикуем и распространяем только отчеты, построенные на основании собранных
       анонимных данных. При этом отчеты не содержат информацию, по которой было бы возможным идентифицировать персонифицированные данные пользователей услуг.
       <br /><br />
       Мы также используем анонимные данные для внутреннего анализа, целью которого является
       развитие продуктов и услуг “Золотой звон”.
       <br /><br /><br />

       4. Ограничение ответственности
       <br /><br />
       Мы делаем все возможное для соблюдения настоящей политики конфиденциальности,
       однако, мы не можем гарантировать сохранность информации в случае воздействия факторов,
       находящихся вне нашего влияния, результатом действия которых станет раскрытие информации.
       <br /><br />
       Сайт “Золотой звон” и вся размещенная на нем информация представлены по принципу
       'как есть” без каких-либо гарантий. Мы не несем ответственности за неблагоприятные
       последствия, а также за любые убытки, причиненные вследствие ограничения доступа
       к сайту “Золотой звон” или вследствие посещения сайта и использования размещенной
       на нем информации.
       <br /><br /><br />

       7. Контакты
       <br /><br />
       По вопросам, касающимся настоящей политики, просьба обращаться по адресу maximsilutin@mail.ru.
     </div>
    </Modal>
  )
}

export default ModalAgreement
