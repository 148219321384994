import React from "react";
import "../../assets/styles/modal.sass";
import Modal from "react-modal";

const IntervalSelector = ({
  isOpen,
  handleClose,
  setInterval,
  selected = null,
}) => {
  const select = (id) => {
    setInterval(id);
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleClose();
      }}
      shouldCloseOnOverlayClick={true}
      className="modal modal-ringing"
    >
      <div className="block ringing-area">
        <div className="ringing-scroll">
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              select("S30");
            }}
            className={`ringing-item${selected === "S30" ? " _selected" : ""}`}
          >
            <div className="ringing-name">Каждые 30 минут</div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              select("S15");
            }}
            className={`ringing-item${selected === "S15" ? " _selected" : ""}`}
          >
            <div className="ringing-name">Каждые 15 минут</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IntervalSelector;
