import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Slider from "react-slick";
import BannerLogo from '../../../assets/images/landing/banner-logo.png'
import Prew from '../../../assets/images/landing/prew.png'
import Next from '../../../assets/images/landing/next.png'

import A11 from "../../../assets/images/landing/a11.jpg";
import A111 from "../../../assets/images/landing/a111.jpg";

const Banner = () => {

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div onClick={onClick} className="slider-arrow _prew" style={{ display: 'none' }}>
        <img src={Prew} alt="Назад" />
      </div>
    );
  }

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div onClick={onClick} className="slider-arrow _next" style={{ display: 'none' }}>
        <img src={Next} alt="Вперед" />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipe: false,
    touchMove: false,
    accessibility: false
  };

  return (
    <div className="banner-slider">
      <Slider {...settings}>
        <div className="container-area-field banner">
          <img className="banner-img" src={A11} alt="Колокольня" />
          <img
            className="banner-img banner-img--mobile"
            src={A111}
            alt="Колокольня"
          />

          <div className="container-main-area">
            <div className="banner-logo__container">
              <img
                src={BannerLogo}
                alt="Золотой звон"
                className="banner-logo"
              />
            </div>
            <h1 className="banner-text">Электронный звонарь</h1>

            <div className="banner__back">
              <HashLink
                to="/#prices"
                smooth="true"
                className="btn btn-yellow order-system-btn"
              >
                Узнать цену
              </HashLink>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
