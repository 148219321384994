import React, { useContext, useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import "moment/locale/ru";
import { ReactComponent as SelectIcon } from "../../../assets/images/select-icon.svg";
import { StateContext } from "../../../context/StateContext";
import Loading from "../../../components/loading/loading";
import melodies15 from "../../../helpers/melodies15";
import melodies255 from "../../../helpers/melodies255";
import MelodySelect from "../../../components/modal/melodySelect";
import Logs from "./Logs";
import Header from "../../../components/header/header";
import { fetchMelodiesByDevice } from "../../../helpers/fetchMelodies";

const Timers = () => {
  const STATE = useContext(StateContext);
  const [timers, setTimers] = useState([]);
  const [modalOpen, setModalOpen] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [modalItems, setModalItems] = useState([]);
  const [openDatetime, setOpenDatetime] = useState(null);

  const fetchingTitles = async () => {
    const titles = await fetchMelodiesByDevice(STATE.lastData.deviceId);

    let melodies = [];

    const srcMelodies =
      STATE.lastData.maxMelodies !== undefined ? melodies255 : melodies15;

    [
      ...srcMelodies.filter(
        (item) => item.id > 0 && item.id <= (STATE.lastData.maxMelodies ?? 15)
      ),
    ].forEach((item) => {
      if (item.id in titles) {
        melodies.push({ ...item, title: titles[item.id] });
      } else {
        melodies.push({ ...item });
      }
    });

    setModalItems(melodies);
  };

  useEffect(() => {
    // if (!!STATE.lastData && STATE.lastData.settings.version === 5) {
    if (!!STATE.lastData) {
      fetchingTitles();
    }
  }, [STATE.lastData]);

  useEffect(() => {
    const newTimers = [];

    if (STATE.lastData && STATE.lastData.timers) {
      STATE.lastData.timers.forEach((item) => {
        newTimers.push({ ...item });
      });

      // if (STATE.lastData.settings.version === 5) {
        fetchingTitles();
      // } 
      // else {
      //   setModalItems([...melodies15.filter((item) => item.id < 11)]);
      // }
    }
    setTimers(newTimers);
  }, []);

  let datetimes = [];
  let rings = [];
  let melodyTitles = [];

  if (timers) {
    timers.forEach((timer) => {
      const string =
        `${timer.hour.toString().length > 1 ? timer.hour : `0${timer.hour}`}:` +
        `${
          timer.minute.toString().length > 1 ? timer.minute : `0${timer.minute}`
        }:` +
        `00 ${timer.year + 2000}.` +
        `${
          timer.month.toString().length > 1 ? timer.month : `0${timer.month}`
        }.` +
        `${timer.day.toString().length > 1 ? timer.day : `0${timer.day}`}`;

      rings.push(timer.ring);
      datetimes.push(string);

      modalItems.forEach((item) => {
        if (parseInt(item.id, 10) === parseInt(timer.ring, 16))
          melodyTitles.push(item.title);
      });
    });
  }

  const switchTimer = (index) => {
    const newTimers = [...timers];

    newTimers[index].status = newTimers[index].status === 1 ? 0 : 1;

    setTimers(newTimers);
  };

  const changeDatetime = (value, index) => {
    const newTimers = [...timers];

    newTimers[index].hour = parseInt(value.format("HH"), 10);
    newTimers[index].minute = parseInt(value.format("mm"), 10);
    newTimers[index].day = parseInt(value.format("DD"), 10);
    newTimers[index].month = parseInt(value.format("MM"), 10);
    newTimers[index].year = parseInt(
      `${value.format("YYYY")[2]}${value.format("YYYY")[3]}`,
      10
    );

    setTimers(newTimers);
  };

  const setMelody = (value, index) => {
    const newTimers = [...timers];
    newTimers[index].ring = value;
    setTimers(newTimers);
  };

  const setModal = (value, index) => {
    const newModalOpen = [...modalOpen];
    newModalOpen[index] = value;
    setModalOpen(newModalOpen);
  };

  const week = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

  const renderDays = (timerIndex) => {
    const days = week.map((day, index) => {
      const isActive = !!timers[timerIndex].repeats[index];
      return (
        <div
          key={day}
          onClick={() => toggleRepeat(timerIndex, index)}
          className={`week-day${isActive ? " week-day--active" : ""}`}
        >
          {day}
        </div>
      );
    });

    return <div className="week-container">{days}</div>;
  };

  const toggleRepeat = (timerIndex, dayIndex) => {
    const newRepeats = timers[timerIndex].repeats;
    newRepeats[dayIndex] = newRepeats[dayIndex] === 0 ? 1 : 0;

    const newTimers = [...timers];
    newTimers[timerIndex].repeats = newRepeats;

    setTimers(newTimers);
  };

  const save = () => {
    STATE.sendData({ timers });
  };

  if (timers.length === 0) {
    return (
      <div className="main-area-field full-height">
        <div className="main-area full-height">
          <div className="page-area color-white no-p">
            <Header
              title={"Таймеры"}
              backTitle="Пульт"
              backUrl={`/device/${STATE.subscribed.id}`}
            />

            <Logs />

            <div className="page-area center-h color-white">
              <Loading />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const toggleDatetime = (index) => {
    if (openDatetime === index) {
      setOpenDatetime(null);
    } else {
      setOpenDatetime(index);
    }
  };

  return (
    <div className="main-area-field full-height">
      <div className="main-area full-height">
        <div className="page-area color-white no-p">
          <Header
            title={"Таймеры"}
            backTitle="Пульт"
            backUrl={`/device/${STATE.subscribed.id}`}
          />

          <Logs />

          <div className="page-area center-h color-white">
            <div className="chimes-content">
              <div className="chimes-list timers-list">
                <div
                  className={`chimes-item timers-item${
                    !!timers[0].status ? " _active" : ""
                  } icon-1`}
                >
                  <div className="chimes-time">
                    <div
                      className="input-link chimes-options"
                      onClick={() => setModal(true, 0)}
                    >
                      <div className="input-container _no-mr">
                        <div className="input-area">
                          <input type="text" readOnly value={melodyTitles[0]} />
                          <SelectIcon className="select-icon _abs" />
                          <MelodySelect
                            isOpen={modalOpen[0]}
                            selected={rings[0]}
                            items={modalItems}
                            modalIndex={0}
                            setModal={setModal}
                            setMelody={setMelody}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="switch-area">
                      <input
                        type="checkbox"
                        id="switch-0"
                        checked={!!timers[0].status}
                        onChange={() => switchTimer(0)}
                      />
                      <label
                        role="button"
                        tabIndex="0"
                        className="radio"
                        htmlFor="switch-0"
                      />
                    </div>
                  </div>

                  <div className="mode-time mode-timers">
                    <div className="custom-select-area">
                      <Datetime
                        dateFormat="DD.MM.YYYY"
                        timeFormat="HH:mm"
                        open={openDatetime === 0}
                        inputProps={{
                          readOnly: true,
                          onClick: () => toggleDatetime(0),
                        }}
                        value={moment(datetimes[0], "HH:mm:ss YYYY.MM.DD")}
                        onChange={(value) => changeDatetime(value, 0)}
                      />
                      <SelectIcon
                        className={`select-icon _abs${
                          openDatetime === 0 ? " _rotate" : ""
                        }`}
                      />
                    </div>
                  </div>

                  {STATE.lastData?.settings?.version >= 3.5 && renderDays(0)}
                </div>

                <div
                  className={`chimes-item timers-item${
                    !!timers[1].status ? " _active" : ""
                  } icon-2`}
                >
                  <div className="chimes-time">
                    <div
                      className="input-link chimes-options"
                      onClick={() => setModal(true, 1)}
                    >
                      <div className="input-container _no-mr">
                        <div className="input-area">
                          <input type="text" readOnly value={melodyTitles[1]} />
                          <SelectIcon className="select-icon _abs" />
                          <MelodySelect
                            isOpen={modalOpen[1]}
                            selected={rings[1]}
                            items={modalItems}
                            modalIndex={1}
                            setModal={setModal}
                            setMelody={setMelody}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="switch-area">
                      <input
                        type="checkbox"
                        id="switch-1"
                        checked={!!timers[1].status}
                        onChange={() => switchTimer(1)}
                      />
                      <label
                        role="button"
                        tabIndex="0"
                        className="radio"
                        htmlFor="switch-1"
                      />
                    </div>
                  </div>

                  <div className="mode-time mode-timers">
                    <div className="custom-select-area">
                      <Datetime
                        dateFormat="DD.MM.YYYY"
                        timeFormat="HH:mm"
                        open={openDatetime === 1}
                        inputProps={{
                          readOnly: true,
                          onClick: () => toggleDatetime(1),
                        }}
                        value={moment(datetimes[1], "HH:mm:ss YYYY.MM.DD")}
                        onChange={(value) => changeDatetime(value, 1)}
                      />
                      <SelectIcon
                        className={`select-icon _abs${
                          openDatetime === 1 ? " _rotate" : ""
                        }`}
                      />
                    </div>
                  </div>

                  {STATE.lastData?.settings?.version >= 3.5 && renderDays(1)}
                </div>

                <div
                  className={`chimes-item timers-item${
                    !!timers[2].status ? " _active" : ""
                  } icon-3`}
                >
                  <div className="chimes-time">
                    <div
                      className="input-link chimes-options"
                      onClick={() => setModal(true, 2)}
                    >
                      <div className="input-container _no-mr">
                        <div className="input-area">
                          <input type="text" readOnly value={melodyTitles[2]} />
                          <SelectIcon className="select-icon _abs" />
                          <MelodySelect
                            isOpen={modalOpen[2]}
                            selected={rings[2]}
                            items={modalItems}
                            modalIndex={2}
                            setModal={setModal}
                            setMelody={setMelody}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="switch-area">
                      <input
                        type="checkbox"
                        id="switch-2"
                        checked={!!timers[2].status}
                        onChange={() => switchTimer(2)}
                      />
                      <label
                        role="button"
                        tabIndex="0"
                        className="radio"
                        htmlFor="switch-2"
                      />
                    </div>
                  </div>

                  <div className="mode-time mode-timers">
                    <div className="custom-select-area">
                      <Datetime
                        dateFormat="DD.MM.YYYY"
                        timeFormat="HH:mm"
                        open={openDatetime === 2}
                        inputProps={{
                          readOnly: true,
                          onClick: () => toggleDatetime(2),
                        }}
                        value={moment(datetimes[2], "HH:mm:ss YYYY.MM.DD")}
                        onChange={(value) => changeDatetime(value, 2)}
                      />
                      <SelectIcon
                        className={`select-icon _abs${
                          openDatetime === 2 ? " _rotate" : ""
                        }`}
                      />
                    </div>
                  </div>

                  {STATE.lastData?.settings?.version >= 3.5 && renderDays(2)}
                </div>

                <div
                  className={`chimes-item timers-item${
                    !!timers[3].status ? " _active" : ""
                  } icon-4`}
                >
                  <div className="chimes-time">
                    <div
                      className="input-link chimes-options"
                      onClick={() => setModal(true, 3)}
                    >
                      <div className="input-container _no-mr">
                        <div className="input-area">
                          <input type="text" readOnly value={melodyTitles[3]} />
                          <SelectIcon className="select-icon _abs" />
                          <MelodySelect
                            isOpen={modalOpen[3]}
                            selected={rings[3]}
                            items={modalItems}
                            modalIndex={3}
                            setModal={setModal}
                            setMelody={setMelody}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="switch-area">
                      <input
                        type="checkbox"
                        id="switch-3"
                        checked={!!timers[3].status}
                        onChange={() => switchTimer(3)}
                      />
                      <label
                        role="button"
                        tabIndex="0"
                        className="radio"
                        htmlFor="switch-3"
                      />
                    </div>
                  </div>

                  <div className="mode-time mode-timers">
                    <div className="custom-select-area">
                      <Datetime
                        dateFormat="DD.MM.YYYY"
                        timeFormat="HH:mm"
                        open={openDatetime === 3}
                        inputProps={{
                          readOnly: true,
                          onClick: () => toggleDatetime(3),
                        }}
                        value={moment(datetimes[3], "HH:mm:ss YYYY.MM.DD")}
                        onChange={(value) => changeDatetime(value, 3)}
                      />
                      <SelectIcon
                        className={`select-icon _abs${
                          openDatetime === 3 ? " _rotate" : ""
                        }`}
                      />
                    </div>
                  </div>

                  {STATE.lastData?.settings?.version >= 3.5 && renderDays(3)}
                </div>

                <div
                  className={`chimes-item timers-item${
                    !!timers[4].status ? " _active" : ""
                  } icon-5`}
                >
                  <div className="chimes-time">
                    <div
                      className="input-link chimes-options"
                      onClick={() => setModal(true, 4)}
                    >
                      <div className="input-container _no-mr">
                        <div className="input-area">
                          <input type="text" readOnly value={melodyTitles[4]} />
                          <SelectIcon className="select-icon _abs" />
                          <MelodySelect
                            isOpen={modalOpen[4]}
                            selected={rings[4]}
                            items={modalItems}
                            modalIndex={4}
                            setModal={setModal}
                            setMelody={setMelody}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="switch-area">
                      <input
                        type="checkbox"
                        id="switch-4"
                        checked={!!timers[4].status}
                        onChange={() => switchTimer(4)}
                      />
                      <label
                        role="button"
                        tabIndex="0"
                        className="radio"
                        htmlFor="switch-4"
                      />
                    </div>
                  </div>

                  <div className="mode-time mode-timers">
                    <div className="custom-select-area">
                      <Datetime
                        dateFormat="DD.MM.YYYY"
                        timeFormat="HH:mm"
                        open={openDatetime === 4}
                        inputProps={{
                          readOnly: true,
                          onClick: () => toggleDatetime(4),
                        }}
                        value={moment(datetimes[4], "HH:mm:ss YYYY.MM.DD")}
                        onChange={(value) => changeDatetime(value, 4)}
                      />
                      <SelectIcon
                        className={`select-icon _abs${
                          openDatetime === 4 ? " _rotate" : ""
                        }`}
                      />
                    </div>
                  </div>

                  {STATE.lastData?.settings?.version >= 3.5 && renderDays(4)}
                </div>
              </div>
              <div className="button-area _center" onClick={() => save()}>
                <button className="btn btn-yellow chimes-save">
                  {!STATE.isSending && "СОХРАНИТЬ"}
                  {STATE.isSending && <Loading />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timers;
