import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as EyeH } from "../../assets/images/eye-hidden.svg";
import { ReactComponent as EyeV } from "../../assets/images/eye-visib.svg";
import api from "./api";
import MetaTags from "react-meta-tags";
import Header from "../../components/header/header";

const Reset = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [errors, setErrors] = useState({});
  const [passwordSee, setPasswordSee] = useState(false);
  const [passwordAgainSee, setPasswordAgainSee] = useState(false);

  const resetStep = () => {
    setPassword("");
    setPasswordAgain("");
    setPasswordSee(false);
    setPasswordAgainSee(false);
    setToken("");
    setStep(1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors({});

    try {
      if (step === 1) await api.reset({ email });
      if (step === 2)
        await api.resetConfirm({ token, password, passwordAgain });

      setStep(step + 1);
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error) setErrors(error.response.data.error);
        if (error.response.data.message)
          setErrors({ message: error.response.data.message });
      } else {
        setErrors({ message: "Что-то пошло не так" });
      }
    }
  };

  return (
    <div className="main-area-field full-height">
      <MetaTags>
        <title>Золотой звон | Сброс пароля</title>
        <meta
          name="description"
          content="Сбросить пароль от личного кабинете в системе «Золотой звон»"
        />
        <meta property="og:title" content="Золотой звон | Сброс пароля" />
      </MetaTags>

      <div className="main-area full-height">
        <div className="page-area center-h ">
          {/* <Link
            to="/login"
            className="title-block under-title under-title--home"
          >
            <BackIcon className="icon" />
            <div className="title">Назад</div>
          </Link> */}
          <Header title={"Сброс пароля"} backTitle={"Назад"} backUrl={"/login"} />

          <div className="center-style">
            <form onSubmit={onSubmit} className="enter-form-area">
              {step === 1 && (
                <React.Fragment>
                  <div className="title-form-block">
                    <div className="title">Введите email вашего профиля</div>
                    <div className="sub-title">
                      На почту будет оправлен код подтверждения
                    </div>
                  </div>

                  <div className="input-container">
                    <div
                      className={`input-area${
                        errors.email ? " input-error" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={email}
                        name="email"
                        placeholder="E-mail"
                        onChange={(e) => setEmail(e.target.value.trim())}
                      />
                    </div>
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </div>
                </React.Fragment>
              )}

              {step === 2 && (
                <React.Fragment>
                  <div className="title-form-block">
                    <div className="title">Установите новый пароль</div>
                    <div className="sub-title">
                      Код подтверждения был оправлен на почту
                    </div>
                  </div>

                  <div
                    className="input-container"
                    style={{ marginBottom: "20px" }}
                  >
                    <div
                      className={`input-area${
                        errors.token ? " input-error" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={token}
                        name="token"
                        maxLength={6}
                        placeholder="Код"
                        onChange={(e) => setToken(e.target.value.trim())}
                      />
                    </div>
                    {errors.token && (
                      <div className="error">{errors.token}</div>
                    )}
                  </div>

                  <div className="input-container">
                    <div
                      className={`input-area${
                        errors.password ? " input-error" : ""
                      }`}
                    >
                      <input
                        type={passwordSee ? "text" : "password"}
                        value={password}
                        name="password"
                        placeholder="Пароль"
                        onChange={(e) => setPassword(e.target.value.trim())}
                      />
                      <div
                        className="form-eye"
                        onClick={(e) => setPasswordSee(!passwordSee)}
                      >
                        {passwordSee ? <EyeV /> : <EyeH />}
                      </div>
                    </div>
                    {errors.password && (
                      <div className="error">{errors.password}</div>
                    )}
                  </div>

                  <div className="input-container">
                    <div
                      className={`input-area${
                        errors.passwordAgain ? " input-error" : ""
                      }`}
                    >
                      <input
                        type={passwordAgainSee ? "text" : "password"}
                        value={passwordAgain}
                        name="password_again"
                        placeholder="Повторите Пароль"
                        onChange={(e) =>
                          setPasswordAgain(e.target.value.trim())
                        }
                      />
                      <div
                        className="form-eye"
                        onClick={(e) => setPasswordAgainSee(!passwordAgainSee)}
                      >
                        {passwordAgainSee ? <EyeV /> : <EyeH />}
                      </div>
                    </div>
                    {errors.passwordAgain && (
                      <div className="error">{errors.passwordAgain}</div>
                    )}
                    {errors.message && (
                      <div className="error">{errors.message}</div>
                    )}
                  </div>
                </React.Fragment>
              )}

              {step === 3 && (
                <React.Fragment>
                  <div className="title-form-block">
                    <div className="title">Новый пароль сохранен</div>
                    <div className="sub-title">
                      Теперь вы можете войти с новыми данными
                    </div>
                  </div>
                </React.Fragment>
              )}

              <div
                className={`button-area ${step === 2 ? "_between" : "_center"}`}
              >
                {step === 2 && (
                  <button
                    type="button"
                    className="btn btn-gray"
                    onClick={resetStep}
                  >
                    Назад
                  </button>
                )}

                {step !== 3 && (
                  <button type="submit" className="btn btn-yellow btn-enter">
                    Далее
                  </button>
                )}

                {step === 3 && (
                  <Link to="/login" className="btn btn-yellow btn-enter">
                    Войти
                  </Link>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
