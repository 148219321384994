import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../../../context/StateContext";
import Loading from "../../../components/loading/loading";
import Logs from "./Logs";
import Header from "../../../components/header/header";
import melodies15 from "../../../helpers/melodies15";
import { ReactComponent as SelectIcon } from "../../../assets/images/select-icon.svg";
import tempos from "../../../helpers/tempos";
import durations from "../../../helpers/durations";
import periods from "../../../helpers/periods";
import durationsB from "../../../helpers/durationsB";
import hits from "../../../helpers/hits";
import bellNumbers from "../../../helpers/bellNumbers";
import BellSettingsSelector from "../../../components/modal/bellSettingsSelect";

const Constructor = () => {
  const STATE = useContext(StateContext);
  const [bellsSettings, setBellsSettings] = useState([]);
  const [openedTempo, setOpenedTempo] = useState(null);
  const [openedDuration, setOpenedDuration] = useState(null);
  const [openedPeriod, setOpenedPeriod] = useState(null);
  const [openedHits, setOpenedHits] = useState(null);
  const [openedBellNumbers, setOpenedBellNumbers] = useState(null);

  const toggleBlagovest = (index) => {
    const newBellsSettings = [...bellsSettings];
    newBellsSettings[index].blagovest = !newBellsSettings[index].blagovest;

    setBellsSettings(newBellsSettings);
  }

  const handleSetting = (index, type, value) => {
    const newBellsSettings = [...bellsSettings];
    newBellsSettings[index][type] = value;

    setBellsSettings(newBellsSettings);
  }

  useEffect(() => {
    const newBellsSettings = [];

    STATE.lastData.bellsSettings.forEach((bell) => {
      newBellsSettings.push({ ...bell });
    });

    setBellsSettings(newBellsSettings);
  }, [STATE.lastData]);

  const save = () => {
    const data = {
      bellsSettings
    };

    STATE.sendData(data);
  };

  const bellNumbersF = {}
  Object.keys(bellNumbers).filter(
    (key) => key <= STATE.lastData.settings.count
  ).forEach((key) => {
    bellNumbersF[key] = bellNumbers[key]
  });

  const bellsSettingsList = bellsSettings.map((bell, index) => {
    // 10
    if (bell.type === "B")
      return (
        <div key={index} className="bell-settings">
          <div className="bell-settings__title">
            {melodies15[index + 1].title}
          </div>
          <div className="bellsettings-item">
            <div className="normal-title">Продолжительность</div>

            <div className="input-container _no-mr _w90">
              <div className="input-area input-area__hovered">
                <input
                  type="text"
                  readOnly
                  value={durationsB[bell.duration]}
                  onClick={() => setOpenedDuration(index)}
                />
                <SelectIcon className="select-icon _abs" />
              </div>

              <BellSettingsSelector
                isOpen={openedDuration === index}
                selected={bell.duration}
                handleClose={() => setOpenedDuration(null)}
                setValue={(value) => handleSetting(index, "duration", value)}
                items={durationsB}
              />
            </div>
          </div>

          <div className="bellsettings-item">
            <div className="normal-title">Период</div>

            <div className="input-container _no-mr _w90">
              <div className="input-area input-area__hovered">
                <input
                  type="text"
                  readOnly
                  value={periods[bell.period]}
                  onClick={() => setOpenedPeriod(index)}
                />
                <SelectIcon className="select-icon _abs" />
              </div>

              <BellSettingsSelector
                isOpen={openedPeriod === index}
                selected={bell.period}
                handleClose={() => setOpenedPeriod(null)}
                setValue={(value) => handleSetting(index, "period", value)}
                items={periods}
              />
            </div>
          </div>
        </div>
      );

    // 9
    if (bell.type === "C")
      return (
        <div key={index} className="bell-settings">
          <div className="bell-settings__title">
            {melodies15[index + 1].title}
          </div>
          <div className="bellsettings-item">
            <div className="normal-title">Период редких</div>
            <div className="input-container _no-mr _w90">
              <div className="input-area input-area__hovered">
                <input
                  type="text"
                  readOnly
                  value={periods[bell.period1]}
                  onClick={() => setOpenedPeriod(index)}
                />
                <SelectIcon className="select-icon _abs" />
              </div>

              <BellSettingsSelector
                isOpen={openedPeriod === index}
                selected={bell.period1}
                handleClose={() => setOpenedPeriod(null)}
                setValue={(value) => handleSetting(index, "period1", value)}
                items={periods}
              />
            </div>
          </div>
          <div className="bellsettings-item">
            <div className="normal-title">Период мерных</div>
            <div className="input-container _no-mr _w90">
              <div className="input-area input-area__hovered">
                <input
                  type="text"
                  readOnly
                  value={periods[bell.period2]}
                  onClick={() => setOpenedPeriod(index)}
                />
                <SelectIcon className="select-icon _abs" />
              </div>

              <BellSettingsSelector
                isOpen={openedPeriod === index}
                selected={bell.period2}
                handleClose={() => setOpenedPeriod(null)}
                setValue={(value) => handleSetting(index, "period2", value)}
                items={periods}
              />
            </div>
          </div>
          <div className="bellsettings-item">
            <div className="normal-title">Колокол</div>

            <div className="input-container _no-mr _w90">
              <div className="input-area input-area__hovered">
                <input
                  type="text"
                  readOnly
                  value={bellNumbersF[bell.bellNumber]}
                  onClick={() => setOpenedBellNumbers(index)}
                />
                <SelectIcon className="select-icon _abs" />
              </div>

              <BellSettingsSelector
                isOpen={openedBellNumbers === index}
                selected={bell.bellNumber}
                handleClose={() => setOpenedBellNumbers(null)}
                setValue={(value) => handleSetting(index, "bellNumber", value)}
                items={bellNumbersF}
              />
            </div>
          </div>
          <div className="bellsettings-item">
            <div className="normal-title">Продолжительность</div>

            <div className="input-container _no-mr _w106">
              <div className="input-area input-area__hovered">
                <input
                  type="text"
                  readOnly
                  value={hits[bell.hits]}
                  onClick={() => setOpenedHits(index)}
                />
                <SelectIcon className="select-icon _abs" />
              </div>

              <BellSettingsSelector
                isOpen={openedHits === index}
                selected={bell.hits}
                handleClose={() => setOpenedHits(null)}
                setValue={(value) => handleSetting(index, "hits", value)}
                items={hits}
              />
            </div>
          </div>
        </div>
      );

    // 1 - 8
    return (
      <div key={index} className="bell-settings">
        <div className="bell-settings__title">
          {melodies15[index + 1].title}
        </div>
        <div className="bellsettings-item">
          <div className="normal-title">Благовест в начале</div>
          <div className="switch-area">
            <input
              type="checkbox"
              id={`swith-${index}`}
              checked={bell.blagovest}
              onChange={() => toggleBlagovest(index)}
            />
            <label htmlFor={`swith-${index}`}></label>
          </div>
        </div>

        <div className="bellsettings-item">
          <div className="normal-title">Продолжительность</div>

          <div className="input-container _no-mr _w90">
            <div className="input-area input-area__hovered">
              <input
                type="text"
                readOnly
                value={durations[bell.duration]}
                onClick={() => setOpenedDuration(index)}
              />
              <SelectIcon className="select-icon _abs" />
            </div>

            <BellSettingsSelector
              isOpen={openedDuration === index}
              selected={bell.duration}
              handleClose={() => setOpenedDuration(null)}
              setValue={(value) => handleSetting(index, "duration", value)}
              items={durations}
            />
          </div>
        </div>

        <div className="bellsettings-item">
          <div className="normal-title">Темп</div>

          <div className="input-container _no-mr _w130">
            <div className="input-area input-area__hovered">
              <input
                type="text"
                readOnly
                value={tempos[bell.tempo]}
                onClick={() => setOpenedTempo(index)}
              />
              <SelectIcon className="select-icon _abs" />
            </div>

            <BellSettingsSelector
              isOpen={openedTempo === index}
              selected={bell.tempo}
              handleClose={() => setOpenedTempo(null)}
              setValue={(value) => handleSetting(index, "tempo", value)}
              items={tempos}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="main-area-field full-height">
      <div className="main-area full-height">
        <div className="page-area no-p">
          <Header
            title={"Конструктор звонов"}
            backTitle="Настройки"
            backUrl={`/device/${STATE.subscribed.id}/settings`}
          />
          <Logs />

          <div className="page-area center-h">
            <div className="content">
              <div className="block trans-block">{bellsSettingsList}</div>

              <div className="button-area _center">
                <button
                  className="btn btn-yellow chimes-save"
                  onClick={() => save()}
                >
                  {!STATE.isSending && "СОХРАНИТЬ"}
                  {STATE.isSending && <Loading />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Constructor;
