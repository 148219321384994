import React from "react";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as Arrow } from "../../../assets/images/landing/arrow.svg";

const Prices = () => {
  return (
    <section className="container-area-field prices-color" id="prices">
      <div className="container-main-area big-container">
        <div className="application-area advantages-area prices-color">
          <div className="title-home title-block title-block--wide top-line">
            <h2 className="title">Цены на электронный звонарь</h2>
          </div>

          <div className="price-table">
            <div className="price-tr">
              <div className="price-th">Кол-во колоколов</div>
              <div className="price-th">Базовый комплект</div>
              <div className="price-th">Монтаж (+материалы)</div>
            </div>

            <div className="price-tr">
              <div className="price-td">1</div>
              <div className="price-td">271 000 ₽</div>
              <div className="price-td">42 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">2</div>
              <div className="price-td">280 000 ₽</div>
              <div className="price-td">44 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">3</div>
              <div className="price-td">292 000 ₽</div>
              <div className="price-td">46 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">4</div>
              <div className="price-td">303 000 ₽</div>
              <div className="price-td">48 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">5</div>
              <div className="price-td">319 000 ₽</div>
              <div className="price-td">50 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">6</div>
              <div className="price-td">340 000 ₽</div>
              <div className="price-td">52 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">7</div>
              <div className="price-td">361 000 ₽</div>
              <div className="price-td">54 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">8</div>
              <div className="price-td">383 000 ₽</div>
              <div className="price-td">56 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">9</div>
              <div className="price-td">409 000 ₽</div>
              <div className="price-td">58 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">10</div>
              <div className="price-td">438 000 ₽</div>
              <div className="price-td">60 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">11</div>
              <div className="price-td">470 000 ₽</div>
              <div className="price-td">62 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">12</div>
              <div className="price-td">507 000 ₽</div>
              <div className="price-td">64 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">13</div>
              <div className="price-td">538 000 ₽</div>
              <div className="price-td">66 000 ₽</div>
            </div>

            <div className="price-tr">
              <div className="price-td">14</div>
              <div className="price-td">569 000 ₽</div>
              <div className="price-td">68 000 ₽</div>
            </div>
          </div>

          <div
            className="order-system-area order-system-area--mobile"
            style={{ justifyContent: "center" }}
          >
            <HashLink to="/#contact" smooth="true" className="btn btn-yellow">
              Купить электронный звонарь
              <Arrow />
            </HashLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Prices;
